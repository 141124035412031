import React, { useContext, useEffect, useRef, useState } from 'react'
import classes from './ShopHeader.module.css'
import { MdHome } from 'react-icons/md'
import { FaBalanceScaleLeft } from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom'
import AuthContext from '../../context/AuthProvider'

const ShopHeader = ({
  setIsOpenLoginModal,
  onChangeSearchTerm,
  searchTerm,
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const navigate = useNavigate()
  const location = useLocation()
  const { auth } = useContext(AuthContext)
  const isLogged = auth && Object.keys(auth).length > 0
  const searchTermRef = useRef(null)
  const from = location?.state?.from

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const getClassNameForPath = (currentPath) => {
    return location?.search?.includes(currentPath) ||
      location?.pathname === '/betko_shop'
      ? classes['selectedMenuItem']
      : ''
  }

  useEffect(() => {
    if (searchTermRef?.current && from === 'nonShopPage') {
      searchTermRef?.current.focus()
    }
  }, [from])

  const handleNavigation = (shopType) => {
    const params = new URLSearchParams()
    params.set('shopType', shopType)
    navigate(`/betko_shop/shop?${params.toString()}`)
  }

  return (
    <div className={classes.shopHeader}>
      {location?.pathname === '/betko_shop' && (
        <div className={classes.shopHeaderBtns}>
          <button onClick={() => !isLogged && setIsOpenLoginModal(true)}>
            {isLogged
              ? 'DOBRO DOŠLI NA BETKOTIP.COM'
              : 'REGISTRACIJA-LOGOVANJE'}
          </button>
          <button onClick={() => navigate('/betko_shop/saradnja')}>
            KONTAKT-SARADNJA
          </button>
        </div>
      )}
      <>
        <div className={classes.shopHeaderMid}>
          <button
            className={classes.headerHomeBtn}
            onClick={() => navigate('/betko_shop')}
          >
            <MdHome />
            <span>POČETNA SHOP</span>
          </button>
          <input
            type='text'
            placeholder='PRETRAGA'
            value={searchTerm}
            onChange={onChangeSearchTerm}
            ref={searchTermRef}
          />
          <button
            className={`${classes.headerHomeBtn} ${classes.backToHomeBtn}`}
            onClick={() => {
              if (isMobile) {
                window.location.href = 'https://betkotip.com/betko_zid'
              } else {
                navigate('/')
              }
            }}
          >
            <MdHome />
            <span>POČETNA BETKO</span>
          </button>
        </div>
      </>
      <div className={classes.shopNavBtns}>
        <button
          className={`${classes.shopBtn} ${getClassNameForPath(
            'shopType=shop'
          )}`}
          onClick={() => handleNavigation('shop')}
        >
          <div className={classes.iconWrapper}>
            <img src='/shop-btn.png' alt='shop' />
          </div>
          <span>SHOP</span>
        </button>
        <button
          className={`${classes.auctionsBtn} ${getClassNameForPath(
            'shopType=auction'
          )}`}
          onClick={() => handleNavigation('auction')}
        >
          <div className={classes.iconWrapper}>
            <FaBalanceScaleLeft />
          </div>
          <span>LICITACIJE</span>
        </button>
        <button
          className={`${classes.sportTuristBtn} ${getClassNameForPath(
            'shopType=sport-tourist'
          )}`}
          onClick={() => handleNavigation('sport-tourist')}
        >
          <div className={classes.iconWrapper}>
            <img src='/sport-turist-btn.png' alt='sport turist' />
          </div>
          <span>SPORT TURIST</span>
        </button>
      </div>
    </div>
  )
}

export default ShopHeader
