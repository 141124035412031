import { useNavigate } from "react-router-dom";
import classes from "./adminSidePanel.module.css";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";

const AdminSidePanel = () => {
  const navigate = useNavigate();
  const { auth } = useContext(AuthContext)

  if (auth && auth.status === 'Admin') {
    return (
      <div className={classes.container}>
        <div className={classes.main}>
          <h3>UPRAVLJANJE STRANICAMA</h3>
          <ul>
            <li onClick={() => navigate("/betko-admin")}>HOME</li>
            <li onClick={() => navigate("/betko-admin/chat-slike")}>
              IZBOR SLIKA ZA "PITAJ" ODELJAK
            </li>
            <li onClick={() => navigate("/betko-admin/footer-slike")}>
              IZBOR SLIKA ZA FOOTER REKLAME
            </li>
            <li onClick={() => navigate("/betko-admin/info-blok")}>INFO BLOK</li>
            <li onClick={() => navigate("/betko-admin/bet-portal")}>
              BET PORTAL
            </li>
            <li onClick={() => navigate("/betko-admin/statistika")}>
              STATISTIKA
            </li>
            <li onClick={() => navigate("/betko-admin/bet-kurs")}>
              BET KURS (STARI)
            </li>
            <li onClick={() => navigate("/betko-admin/bet-kurs-novo")}>
              PREMIUM
            </li>
            <li onClick={() => navigate("/betko-admin/nasi-predlozi")}>
              VAŠI PREDLOZI
            </li>
            <li
              onClick={() => navigate("/betko-admin/bet-kalkulatori-tutorijal")}
            >
              BET KALKULATORI TUTORIJAL
            </li>
            <li onClick={() => navigate("/betko-admin/pokreni-kalkulatore")}>
              POKRENI KALKULATORE
            </li>
            <li onClick={() => navigate("/betko-admin/betko-info-zid")}>
              BETKO INFO ZID
            </li>
            <li onClick={() => navigate("/betko-admin/betko-home-zid")}>
              HOME PAGE VIDEOS
            </li>
          </ul>

          <h3>* MISTER TIPSTER *</h3>
          <ul>
            <li onClick={() => navigate('/betko-admin/mister-tipster')}>UREDI TIPSTER PAROVE</li>
          </ul>
          <ul>
            <li onClick={() => navigate('/betko-admin/mister-tipster-nagrade')}>UREDI TIPSTER NAGRADE</li>
          </ul>
          <ul>
            <li onClick={() => navigate('/betko-admin/mister-tipster-arhiva-nagrada')}>ARHIVA TIPSTER NAGRADA</li>
          </ul>

          <h3>KOMENTARI</h3>
          <ul>
            <li onClick={() => navigate('/betko-admin/komentari')}>SVI KOMENTARI</li>
            <li onClick={() => navigate('/betko-admin/prijavljeni-komentari')}>PRIJAVLJENI KOMENTARI</li>
            <li onClick={() => navigate('/betko-admin/ocene-komentara')}>OCENE KOMENTARA</li>
          </ul>

          <h3>TIMOVI</h3>
          <ul>
            <li onClick={() => navigate('/betko-admin/timovi')}>SVI TIMOVI</li>
          </ul>

          <h3>Vesti</h3>
          <ul>
            <li onClick={() => navigate('/betko-admin/vesti')}>SVE VESTI</li>
          </ul>

          <h2>GALERIJE</h2>
          <ul>
            <li onClick={() => navigate("/betko-admin/galerija")}>
              GALERIJA SLIKA
            </li>
            <li onClick={() => navigate('/betko-admin/avatar-galerija')}>
              GALERIJA AVATARA
            </li>
            <li onClick={() => navigate("/betko-admin/galerija-homepage")}>
              GALERIJA HOMEPAGE SLIKA
            </li>
            <li onClick={() => navigate("/betko-admin/video-galerija")}>
              VIDEO GALERIJA
            </li>
            <li onClick={() => navigate("/betko-admin/video-home-galerija")}>
              HOMEPAGE VIDEO GALERIJA
            </li>
          </ul>

          <h2>KORISNICI</h2>

          <ul>
            <li onClick={() => navigate("/betko-admin/svi-korisnici")}>
              SVI KORISNICI
            </li>
            <li onClick={() => navigate("/betko-admin/blokirani-korisnici")}>
              BLOKIRANI KORISNICI
            </li>
            <li onClick={() => navigate("/betko-admin/korisnicki-paketi")}>
              KORISNIČKI PAKETI
            </li>
            {/* <li onClick={() => navigate("/betko-admin/")}>FREE KORISNICI</li>
          <li onClick={() => navigate("/betko-admin/")}>PLAĆENI NALOZI</li>
          <li onClick={() => navigate("/betko-admin/")}>VIP KORISNICI</li> */}
          </ul>
        </div>
      </div>
    );
  } else {
    if (auth.moderator === true) {
      return (
        <div className={classes.container}>
          <div className={classes.main}>
            <h3>KOMENTARI</h3>
            <ul>
              <li onClick={() => navigate('/betko-admin/komentari')}>SVI KOMENTARI</li>
              <li onClick={() => navigate('/betko-admin/prijavljeni-komentari')}>PRIJAVLJENI KOMENTARI</li>
            </ul>
          </div>
        </div>
      )
    }
  }
};

export default AdminSidePanel;
