import { Fragment, useContext, useEffect, useRef, useState } from 'react'
import classes from './comments.module.css'
import { Button, Modal, notification } from 'antd'
import axios from '../config/axios'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useMutation } from '@tanstack/react-query'
import { AiFillLike, AiFillDislike, AiOutlineGift } from 'react-icons/ai'
import { IoClose } from 'react-icons/io5'
import { FaRegComment } from 'react-icons/fa'
import AuthContext from '../context/AuthProvider'
import { RxTriangleDown, RxTriangleUp } from 'react-icons/rx'
import Pagination from './Pagination'
import Spinner from './Spinner'

const Chat = ({
  user,
  id: defaultId,
  title,
  commentType: commentTypeDefault,
  adminComment,
  isHomePage,
  setCommentCounter,
}) => {
  // Default values for 'id' and 'commentType' need to be updated later for comments on the home page.
  const [id, setId] = useState(defaultId)
  const [commentType, setCommentType] = useState(commentTypeDefault)
  const stringId = id.toString()
  const SERVER_URL = process.env.REACT_APP_SERVER_URL
  const navigate = useNavigate()
  const location = useLocation()
  const { setRefetch, setAuth } = useContext(AuthContext)

  const currentDate = new Date()
  currentDate.setHours(0, 0, 0, 0)

  // states
  const [comment, setComment] = useState('')
  const [answerOnCommentValue, setAnswerOnCommentValue] = useState('')
  const [editComment, setEditComment] = useState(false)
  const [editCommentValue, setEditCommentValue] = useState({})
  // delete modal
  const [isDelModalVisible, setIsDelModalVisible] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})
  const [selectedComment, setSelectedComment] = useState({})
  // report modal
  const [isReportOpen, setIsReportOpen] = useState(false)
  const [reportComment, setReportComment] = useState({})
  // answer on comment
  const [answerOnComment, setAnswerOnComment] = useState(false)
  const [activeAnswer, setActiveAnser] = useState('')
  const [activeAnswerOwner, setActiveAnswerOwner] = useState('')
  const [parentComment, setParentComment] = useState('')
  const [showComments, setShowComments] = useState([])

  // login / register modal
  const [loginModal, setLoginModal] = useState(false)
  const [registerModal, setRegisterModal] = useState(false)

  const [login, setLogin] = useState(true)
  const [email, setEmail] = useState('')
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [checked, setChecked] = useState(false)
  const [checkedTerms, setCheckedTerms] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoginError, setIsLoginError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loginErrorMessage, setLoginErrorMessage] = useState('')
  const [loginEmail, setLoginEmail] = useState('')
  const [loginPassword, setLoginPassword] = useState('')

  // block user
  const [openBlockUserModal, setOpenBlockUserModal] = useState(false)
  const [blockedUser, setBlockedUser] = useState({})
  const [blockReason, setBlockReason] = useState('')
  const [loadingBtn, setLoadingBtn] = useState(false)

  // Gift
  const [isGiftModalVisible, setIsGiftModalVisible] = useState(false)
  const [giftCommentValue, setGiftCommentValue] = useState('')

  const [sliceValue, setSliceValue] = useState(20)

  // All comments
  const [allComments, setAllComments] = useState(null)
  const [refetchTrigger, setRefetchTrigger] = useState(false)

  // Answers
  const [answersParentComment, setAnswersParentComment] = useState(null)
  const [answers, setAnswers] = useState(null)
  const [answersPage, setAnswersPage] = useState(1)

  // Pagination
  const [totalCount, setTotalCount] = useState(0)
  const queryParams = new URLSearchParams(location.search)
  const currentPageFromUrl = parseInt(queryParams.get('page')) || 1
  const [currentPage, setCurrentPage] = useState(currentPageFromUrl)
  const from = '/'

  useEffect(() => {
    setCurrentPage(currentPageFromUrl)
  }, [currentPageFromUrl])

  // Update URL when currentPage changes
  useEffect(() => {
    if (isHomePage) {
      navigate(`?page=${currentPage}`)
    }
  }, [currentPage, isHomePage, navigate])

  const [pinnedComment, setPinnedComment] = useState(null)
  const needPinnedComment = isHomePage && currentPage === 1

  const disqusShortname = 'betkotip-com'
  const disqusConfig = {
    // url: `http://localhost:3000/${id}`,
    url: `https://betkotip.com/${id}`,
    identifier: stringId,
    title: title,
  }

  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const rp =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/
  const validEmail = re.test(email)
  const validPassword = rp.test(password)
  const validLoginEmail = re.test(loginEmail)
  const validLoginPassword = rp.test(loginPassword)

  const handleRegistration = (e) => {
    e.preventDefault()

    if (!userName) {
      setIsError(true)
      setErrorMessage('Username polje je obavezno')
    }
    if (validEmail && validPassword && checked && userName && checkedTerms) {
      setIsError(false)
      setErrorMessage('')
      registerMutation.mutate({ userName, email, password })
    }
    if (!checked) {
      setIsError(true)
      setErrorMessage('Niste potvrdili broj godina')
    }
    if (!checkedTerms) {
      setIsError(true)
      setErrorMessage('Niste potvrdili opšte uslove')
    }
    if (password !== confirmPassword) {
      setIsError(true)
      setErrorMessage('Lozinke se ne podudaraju')
    }
    if (!validPassword) {
      setIsError(true)
      setErrorMessage(
        'Lozinka mora biti minimum 8 karaktera, sadržati bar jedno veliko slovo, malo slovo, broj i specijalan karakter'
      )
    }
    if (!validEmail) {
      setIsError(true)
      setErrorMessage('Email nije ispravan')
    }
  }

  const handleLogin = (e) => {
    e.preventDefault()
    if (validLoginEmail && validLoginPassword) {
      setIsLoginError(false)
      setLoginErrorMessage('')
      loginMutation.mutate({ email: loginEmail, password: loginPassword })
    }
    if (!validLoginPassword) {
      setIsLoginError(true)
      setLoginErrorMessage(
        'Lozinka mora biti minimum 8 karaktera, sadržati bar jedno veliko slovo, malo slovo, broj i specijalan karakter'
      )
    }
    if (!validLoginEmail) {
      setIsLoginError(true)
      setLoginErrorMessage('Email nije ispravan')
    }
  }

  const registerMutation = useMutation({
    mutationFn: (userData) => {
      return axios.post('/register', userData)
    },
    onSuccess: () => {
      setLogin(true)
      setRegisterModal(false)
      setLoginModal(true)
      // exact: true;
    },
    onError: (error) => {
      console.log(error)
      if (error.response.status === 410) {
        setIsError(true)
        setErrorMessage('Username već postoji')
      }
      if (error.response.status === 409) {
        setIsError(true)
        setErrorMessage('Ovaj email je već registrovan')
      }
      if (error.response.status === 401) {
        setIsError(true)
        setErrorMessage('Email nije registrovan')
      }
      if (error.response.status === 429) {
        setIsError(true)
        setErrorMessage(
          'Previse pokusaja logovanja, pokusajte opet za 60 sekundi.'
        )
      }
    },
  })

  const loginMutation = useMutation({
    mutationFn: async (userData) => {
      const response = await axios.post('/auth', userData, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      })
      const accessToken = response?.data?.accessToken
      const roles = response?.data?.roles
      const paidToDate = new Date(response?.data?.paidToDate).getTime()
      const email = response?.data?.email
      const _id = response?.data?._id
      const commentRestricted = response?.data?.commentRestricted
      const status = response?.data?.status
      const username = response?.data?.username
      const emailNotification = response?.data?.emailNotification
      const commentNotification = response?.data?.commentNotification
      const moderator = response?.data?.moderator
      const accessAdminShop = response?.data?.accessAdminShop
      const name = response?.data?.name
      const address = response?.data?.address
      const place = response?.data?.place
      const zipCode = response?.data?.zipCode
      const phoneNumber = response?.data?.phoneNumber
      const misterTipsterNotification = response?.data?.misterTipsterNotification

      setAuth({
        email,
        accessToken,
        roles,
        paidToDate,
        _id,
        commentRestricted,
        status,
        username,
        emailNotification,
        commentNotification,
        moderator,
        accessAdminShop,
        name,
        address,
        place,
        zipCode,
        phoneNumber,
        misterTipsterNotification
      })
    },
    onSuccess: () => {
      navigate(from, { replace: true })
    },
    onError: (error) => {
      if (error.response.status === 401) {
        setIsLoginError(true)
        setLoginErrorMessage('Pogrešan email ili password.')
      }
      if (error.response.status === 429) {
        setIsLoginError(true)
        setLoginErrorMessage(
          'Previse pokusaja logovanja, pokusajte opet za 60 sekundi.'
        )
      }
    },
  })

  const fetchRepliesCountForComment = async (commentId) => {
    try {
      const response = await axios.get(
        `${SERVER_URL}/countCommentResponses/${commentId}`,
        { withCredentials: false }
      )
      return response?.data?.responsesCount
    } catch (error) {
      console.error(
        `Error fetching replies count for comment ${commentId}:`,
        error
      )
      return 0
    }
  }

  const fetchAllComments = async () => {
    let pinnedComm = null
    try {
      const commentsResponse = await axios.get(
        `${SERVER_URL}/allCommentsNew/${currentPage}/${id}`,
        { withCredentials: false }
      )

      !isHomePage &&
        setCommentCounter(() => ({
          commentsLength: commentsResponse?.data?.numberOfParents,
          answersLength: commentsResponse?.data?.numberOfResponses,
          totalComments:
            commentsResponse?.data?.numberOfParents +
            commentsResponse?.data?.numberOfResponses,
        }))

      if (needPinnedComment) {
        try {
          const response = await axios.get(`${SERVER_URL}/getPinnedComment`)
          setPinnedComment(response?.data?.pinnedComment)
          pinnedComm = response?.data?.pinnedComment
        } catch (error) {
          console.error('Error fetching pinned comment:', error)
        }
      }

      const comments =
        needPinnedComment && pinnedComm?._id
          ? [pinnedComm, ...commentsResponse?.data?.comments]
          : commentsResponse?.data?.comments

      const commentsWithRepliesCountPromises = comments.map(async (comment) => {
        const repliesCount = await fetchRepliesCountForComment(comment._id)
        return { ...comment, repliesCount }
      })

      // Wait for all promises to resolve
      const commentsWithRepliesCount = await Promise.all(
        commentsWithRepliesCountPromises
      )

      setAllComments(commentsWithRepliesCount)
      setTotalCount(commentsResponse?.data?.numberOfParents)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchAllComments()

    return () => {}
  }, [refetchTrigger, currentPage])

  const handleChangeComment = (e) => {
    const { value } = e.target

    setComment(value)
  }

  const handleChangeAnswerComment = (e) => {
    const { value } = e.target

    setAnswerOnCommentValue(value)
  }

  const handleAnswerOnComment = async ({ e, item }) => {
    setActiveAnser(item._id)
    setActiveAnswerOwner(item?.owner?._id)

    if (isHomePage) {
      setCommentType(item?.type)
      setId(item?.post)
    }

    if (item.parent === null) {
      setParentComment(item._id)
    } else {
      setParentComment(item.parent)
    }

    if (answerOnComment) {
      setSelectedItem({})
    } else {
      setSelectedItem(item)
    }
  }

  const handleDeleteComment = async (item) => {
    try {
      await axios
        .delete(`/deleteComment/${item._id}?postId=${id}&type=${commentType}`)
        .then((res) => {
          if (res.status === 200) {
            setSelectedItem({})
            setSelectedComment({})
            // trigger fetch comments
            setRefetchTrigger((prev) => !prev)
            // notification
            notification.success({
              message: 'Komentar uspešno obrisan',
              placement: 'topRight',
            })
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleBlockUserModal = (commentOwner) => {
    if (user && Object.keys(user).length < 1) {
      notification.info({
        message: 'Morate biti ulogovani.',
        placement: 'topRight',
      })

      return
    }
    setOpenBlockUserModal(true)
    setBlockedUser(commentOwner)
  }

  const handleBlockUser = async (e) => {
    e.preventDefault()
    const payload = {
      userId: user._id,
      reason: blockReason,
    }

    if (!blockReason) {
      notification.error({
        message: 'Unesite razlog blokiranja.',
        placement: 'topRight',
      })
      return
    }

    setLoadingBtn(true)

    try {
      await axios
        .post(`${SERVER_URL}/user/block-user/${blockedUser.email}`, payload)
        .then((res) => {
          if (res.status == 200) {
            notification.success({
              message: res.data.message,
              placement: 'topRight',
            })

            setRefetchTrigger((prev) => !prev)
            setOpenBlockUserModal(false)
            setLoadingBtn(false)
            setBlockReason('')
          }
        })
        .catch((error) => {
          console.error(error)
          notification.error({
            message: 'Greška prilikom blokiranja.',
            placement: 'topRight',
          })
          setLoadingBtn(false)
        })
    } catch (error) {
      console.error(error)
      notification.error({
        message: 'Greška prilikom blokiranja.',
        placement: 'topRight',
      })
      setLoadingBtn(false)
    }
  }

  const handleSaveComment = async (item) => {
    if (editCommentValue.comment === '' || !editCommentValue) {
      // notification
      notification.error({
        message: 'Komentar ne može biti prazan',
        placement: 'topRight',
      })

      return
    }
    try {
      const payload = {
        comment: editCommentValue?.comment,
      }

      await axios
        .put(`/updateComment/${item._id}`, payload, { withCredentials: false })
        .then((res) => {
          if (res.status === 200) {
            setEditComment(false)
            setEditCommentValue('')
            setRefetchTrigger((prev) => !prev)

            // notification
            notification.success({
              message: 'Uspešno ste izmenili komentar',
              placement: 'topRight',
            })
          }
        })
        .catch((error) => {
          console.error(error)
          // notification
          notification.error({
            message:
              error.response.data.message ||
              'Došlo je do greške prilikom izmene komentara.',
            placement: 'topRight',
          })
        })
    } catch (error) {
      console.error(error)
      // notification
      notification.error({
        message:
          error.response.data.message ||
          'Došlo je do greške prilikom izmene komentara.',
        placement: 'topRight',
      })
    }
  }

  const handleEditComment = async (item) => {
    try {
      await axios
        .get(`/getComment/${item._id}`, { withCredentials: false })
        .then((res) => {
          if (res.status === 200) {
            setEditCommentValue(res?.data?.data)
            setEditComment(true)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleReportCommentModal = async (item) => {
    if (user && Object.keys(user).length > 0) {
      setIsReportOpen(true)
      setReportComment(item)
    } else {
      notification.info({
        message: 'Morate biti ulogovani.',
        placement: 'topRight',
      })
    }
  }

  const fetchReplayComments = async () => {
    try {
      await axios
        .get(
          `${SERVER_URL}/allCommentsNew/${answersPage}/${answersParentComment.post}/${answersParentComment.parent}`,
          {
            withCredentials: false,
          }
        )
        .then((res) => {
          if (res.status === 200) {
            setAnswers(res.data.comments)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    !!answersParentComment && fetchReplayComments()
  }, [answersParentComment, answersPage, refetchTrigger])

  const handleReportComment = async (e) => {
    const payload = {
      userId: user._id,
    }
    try {
      await axios
        .put(`${SERVER_URL}/commentReports/${reportComment._id}`, payload, {
          withCredentials: false,
        })
        .then((res) => {
          setIsReportOpen(false)
          setReportComment({})
          setRefetchTrigger((prev) => !prev)

          notification.success({
            message: 'Uspešno ste prijavili komentar.',
            placement: 'topRight',
          })
        })
        .catch((error) => {
          console.error(error)
          setIsReportOpen(false)
          notification.error({
            message: 'Greška prilikom prijavljivanja komentara.',
            placement: 'topRight',
          })
        })
    } catch (error) {
      console.error(error)
      setIsReportOpen(false)
      notification.error({
        message: 'Greška prilikom prijavljivanja komentara.',
        placement: 'topRight',
      })
    }
  }

  const dateWithHours = (createdAt) => {
    const date = new Date(createdAt)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    const hours = String(date.getHours()).padStart(2, '0')
    const minutes = String(date.getMinutes()).padStart(2, '0')

    const formattedDateTime = `${day}.${month}.${year} - ${hours}:${minutes}`
    return formattedDateTime
  }

  const buildTree = (items, parent = null) => {
    const tree = []

    items &&
      items.length > 0 &&
      items.forEach((item) => {
        if (item.parent === parent) {
          const children = buildTree(items, item._id)
          if (children.length > 0) {
            item.children = children
          }
          tree.push(item)
        }
      })

    return tree
  }

  const renderTextWithLinks = (text) => {
    // Regular expression to match URLs
    const urlRegex = /(https?:\/\/[^\s]+)/g

    // Replace URLs with anchor tags
    let html = text.replace(
      urlRegex,
      (url) =>
        `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`
    )

    // Replace newlines with <br> tags
    html = html.replace(/\n/g, '<br>')

    return { __html: html }
  }

  const treeData = buildTree(allComments)

  const handleLikeDislikeComment = async ({ type, action }) => {
    const payload = {
      userId: user._id,
    }
    const commentId = action.payload._id

    switch (type) {
      case 'like':
        if (user && Object.keys(user).length > 0) {
          if (user.status === 'Admin') {
            try {
              await axios
                .put(`/adminLikes/${commentId}`, payload, {
                  withCredentials: false,
                })
                .then((res) => {
                  if (res.status === 201) {
                    setRefetchTrigger((prev) => !prev)
                  }
                })
                .catch((error) => {
                  console.error(error)
                })
            } catch (error) {
              console.error(error)
            }
          } else {
            try {
              await axios
                .put(`/likeComment/${commentId}`, payload, {
                  withCredentials: false,
                })
                .then((res) => {
                  if (res.status === 200) {
                    setRefetchTrigger((prev) => !prev)
                  }
                })
                .catch((error) => {
                  console.error(error)
                })
            } catch (error) {
              console.error(error)
            }
          }
        } else {
          notification.info({
            message: 'Morate biti ulogovani.',
            placement: 'topRight',
          })
        }
        break
      case 'dislike':
        if (user && Object.keys(user).length > 0) {
          if (user.status === 'Admin') {
            try {
              await axios
                .put(`/adminDisLikes/${commentId}`, payload, {
                  withCredentials: false,
                })
                .then((res) => {
                  if (res.status === 201) {
                    setRefetchTrigger((prev) => !prev)
                  }
                })
                .catch((error) => {
                  console.error(error)
                })
            } catch (error) {
              console.error(error)
            }
          } else {
            try {
              await axios
                .put(`/dislike/${commentId}`, payload, {
                  withCredentials: false,
                })
                .then((res) => {
                  if (res.status === 200) {
                    setRefetchTrigger((prev) => !prev)
                  }
                })
                .catch((error) => {
                  console.error(error)
                })
            } catch (error) {
              console.error(error)
            }
          }
        } else {
          notification.info({
            message: 'Morate biti ulogovani.',
            placement: 'topRight',
          })
        }
        break
      default:
        console.log('Default state of like dislike function')
    }
  }

  const handlePaste = (e) => {}

  const changeColors = (averageRating) => {
    if (averageRating === 0) {
      return classes.blackRate
    }
    if (averageRating <= 1.49) {
      return classes.crimsonRate
    }
    if (averageRating > 1.49 && averageRating <= 2.49) {
      return classes.redRate
    }
    if (averageRating > 2.49 && averageRating <= 3.49) {
      return classes.orangeRate
    }
    if (averageRating > 3.49 && averageRating <= 4.49) {
      return classes.lightgreenRate
    }
    if (averageRating > 4.49) {
      return classes.greenRate
    }
  }

  const activeRateColor = (item, rate, style) => {
    const findUser = item?.ratings?.find((i) => i.user === user._id)
    if (findUser) {
      if (findUser.rating === rate) {
        return style
      }
    }
  }

  const alreadyRatedComment = (item) => {
    let checkCase = false
    if (item.ratings.some((i) => i.user === user._id)) {
      checkCase = true
    }

    return checkCase
  }

  const handleAddComment = async ({ e, type }) => {
    e.preventDefault()

    if (user && Object.keys(user).length < 1) {
      setLoginModal(true)
      return
    }
    if (
      user.commentRestricted &&
      new Date(user.commentRestricted).setHours(0, 0, 0, 0) > currentDate
    ) {
      const restrictedDate = new Date(user.commentRestricted)
      restrictedDate.setHours(0, 0, 0, 0)
      const diff = restrictedDate - currentDate
      const millisecondsInDay = 1000 * 60 * 60 * 24
      const differenceInDays = Math.floor(diff / millisecondsInDay)
      const days =
        differenceInDays === 36525 ? 'trajno' : `${differenceInDays} dan/a`

      notification.info({
        message: `Zabranjeno Vam je komentarisanje ${days}`,
        placement: 'topRight',
      })
      return
    }
    if (
      user.status !== 'Admin' &&
      commentType === 'bet_kurs_novo' &&
      type === 'comment'
    ) {
      notification.info({
        message:
          'Samo Admin može da komentariše, vi možete da postavljate odgovore na komentare !',
        placement: 'topRight',
      })
      return
    }

    if (adminComment && user.status !== 'Admin' && type === 'comment') {
      notification.info({
        message:
          'Samo Admin može da komentariše, vi možete da postavljate odgovore na komentare !',
        placement: 'topRight',
      })
      return
    }

    let payload = {}
    switch (type) {
      case 'comment':
        if (comment === '' || comment === 'none' || !comment) {
          notification.error({
            message: 'Unesite komentar prvo.',
            placement: 'topRight',
          })
          return
        }

        payload = {
          owner: user._id,
          // title: 'test title',
          comment: comment,
          parent: null,
          type: commentType,
          post: id,
          answerTo: null,
        }
        break
      case 'answer':
        if (
          answerOnCommentValue === '' ||
          answerOnCommentValue === 'none' ||
          !answerOnCommentValue
        ) {
          notification.error({
            message: 'Unesite odgovor prvo.',
            placement: 'topRight',
          })
          return
        }

        payload = {
          owner: user._id,
          // title: 'test title',
          comment: answerOnCommentValue,
          parent: parentComment?._id || parentComment,
          type: commentType,
          post: id,
          answerTo: activeAnswerOwner,
        }
        break
      default:
        console.log('Default state of add comment')
    }
    try {
      await axios
        .post(`/addComment`, payload, { withCredentials: false })
        .then((res) => {
          if (res.status === 200) {
            setComment('')
            setAnswerOnComment('')
            setSelectedItem({})
            setSelectedComment({})
            setAnswerOnCommentValue('')
            // trigger fetch
            setRefetch((prev) => !prev)
            setRefetchTrigger((prev) => !prev)
            // notification
            if (type === 'answer') {
              notification.success({
                message: 'Odgovor uspešno dodat',
                placement: 'topRight',
              })
            } else {
              notification.success({
                message: 'Komentar uspešno kreiran',
                placement: 'topRight',
              })
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleShowComments = (item) => {
    if (showComments.includes(item._id)) {
      const filteredComments = showComments.filter((i) => i !== item._id)
      setShowComments(filteredComments)
    } else {
      setShowComments((prev) => {
        return [...prev, item._id]
      })
    }
  }

  const handleRateComment = async (number, comment) => {
    if (user && Object.keys(user).length < 1) {
      notification.info({
        message: 'Morate biti ulogovani.',
        placement: 'topRight',
      })
      return
    }

    const payload = {
      number,
      userId: user._id,
    }
    try {
      await axios
        .post(`${SERVER_URL}/rateComment/${comment._id}`, payload, {
          withCredentials: false,
        })
        .then((res) => {
          if (res.status === 200) {
            // trigger fetch comments
            setRefetchTrigger((prev) => !prev)
          }
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const restricted =
    user && Object.keys(user).length > 0
      ? user.commentRestricted === true
        ? true
        : false
      : true
  const restrictedTextArea = () => {
    let checkCase = false

    if (commentType === 'bet_kurs_novo') {
      if (user && Object.keys(user).length > 0) {
        if (user.status === 'Admin') {
          if (
            user.commentRestricted &&
            new Date(user.commentRestricted).setHours(0, 0, 0, 0) > currentDate
          ) {
            checkCase = true
          } else {
            checkCase = false
          }
        } else {
          checkCase = true
        }
      } else {
        checkCase = true
      }
    } else {
      if (user && Object.keys(user).length > 0) {
        if (adminComment) {
          if (user.status === 'Admin') {
            if (
              user.commentRestricted &&
              new Date(user.commentRestricted).setHours(0, 0, 0, 0) >
                currentDate
            ) {
              checkCase = true
            } else {
              checkCase = false
            }
          } else {
            checkCase = true
          }
        } else {
          if (
            user.commentRestricted &&
            new Date(user.commentRestricted).setHours(0, 0, 0, 0) > currentDate
          ) {
            checkCase = true
          } else {
            checkCase = false
          }
        }
      } else {
        checkCase = true
      }
    }

    return checkCase
  }

  const handleShowPopUpLogin = ({ type }) => {
    if (user && Object.keys(user).length < 1) {
      setLoginModal(true)
      return
    }
    if (
      user.commentRestricted &&
      new Date(user.commentRestricted).setHours(0, 0, 0, 0) > currentDate
    ) {
      const restrictedDate = new Date(user.commentRestricted)
      restrictedDate.setHours(0, 0, 0, 0)
      const diff = restrictedDate - currentDate
      const millisecondsInDay = 1000 * 60 * 60 * 24
      const differenceInDays = Math.floor(diff / millisecondsInDay)
      const days =
        differenceInDays === 36525 ? 'trajno' : `${differenceInDays} dan/a`

      notification.info({
        message: `Zabranjeno Vam je komentarisanje ${days}`,
        placement: 'topRight',
      })
      return
    }
    if (
      user.status !== 'Admin' &&
      commentType === 'bet_kurs_novo' &&
      type === 'comment'
    ) {
      notification.info({
        message:
          'Samo Admin može da komentariše, vi možete da postavljate odgovore na komentare !',
        placement: 'topRight',
      })
      return
    }

    if (adminComment && user.status !== 'Admin' && type === 'comment') {
      notification.info({
        message:
          'Samo Admin može da komentariše, vi možete da postavljate odgovore na komentare !',
        placement: 'topRight',
      })
      return
    }
  }

  const restrictedBool = restrictedTextArea()

  // Automatically close the modal after 10 seconds if it is opened
  useEffect(() => {
    let timer
    if (isGiftModalVisible) {
      timer = setTimeout(() => {
        setIsGiftModalVisible(false)
        setGiftCommentValue('')
      }, 10000)
    }
    return () => clearTimeout(timer)
  }, [isGiftModalVisible])

  // Scroll the container to the top when currentPage changes
  useEffect(() => {
    const commentsContainer = document.getElementById('commentsContainer')

    if (commentsContainer) {
      commentsContainer.scrollTop = 0
    }
  }, [currentPage])

  const paginationProps = {
    setCurrentPage: setCurrentPage,
    limit: 10,
    currentPage: currentPage,
    totalCount: totalCount,
    totalPages: Math.ceil(totalCount / 10),
    buttonLimit: 3,
  }

  if (!allComments) {
    return (
      <div className={classes.allCommentsLoaderWrapper}>
        <Spinner />
      </div>
    )
  }

  return (
    <div className={classes.mainCenter} id='commentsContainer'>
      {!isHomePage && (
        <div className={classes.leaveCommentContainer}>
          <div className={classes.logInContainer}>
            {user && Object.keys(user).length > 0 ? (
              <span>{user.username}</span>
            ) : (
              <span>. . .uloguj se. . .</span>
            )}
          </div>
          <div className={classes.leaveCommentHeader}>
            <div
              onClick={() => handleShowPopUpLogin({ type: 'comment' })}
              style={{ position: 'relative' }}
            >
              <textarea
                style={{ pointerEvents: restrictedBool && 'none' }}
                disabled={restrictedBool}
                value={comment}
                onChange={(e) => handleChangeComment(e)}
                className={classes.textArea}
                rows={5}
              />
              <span className={classes.placeholder}>
                upiši svoj komentar...
              </span>
            </div>
            <div className={classes.leaveCommentButtonContainer}>
              <button
                onClick={(e) => handleAddComment({ e: e, type: 'comment' })}
                type='button'
              >
                Postavi komentar
              </button>
            </div>
          </div>
        </div>
      )}

      {treeData && treeData.length > 0 ? (
        treeData
          .map((item, index) => {
            const manageComment = item.owner && item.owner._id === user._id
            const commentLikes = item.likes.length
            const commentDislikes = item.dislikes.length
            const commentAdminLikes = item.adminLikes
            const commentAdminDislikes = item.adminDislikes
            const alreadyLiked = item.likes.includes(user._id) ? true : false
            const alreadyDisliked = item.dislikes.includes(user._id)
              ? true
              : false
            let commentIndex = totalCount - (currentPage - 1) * 10 - index
            if (pinnedComment?._id && currentPage === 1) {
              commentIndex += 1
            }

            const customTitleComment =
              item.type === 'vasi_predlozi'
                ? `Predlog br. ${commentIndex}`
                : item.type === 'bet_kurs_novo'
                ? `Premium br. ${commentIndex}`
                : `Komentar br. ${commentIndex}`

            const innerHtml = item?.comment
            const cuttedInnerHtml = item?.comment.substring(0, 450)
            const commentGift = item?.gift
            const commentDate = item.editDate ? item.editDate : item.createdAt
            item?.children?.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
            const averageRating =
              item?.ratings?.reduce(
                (acc, ratingObj) => acc + ratingObj?.rating,
                0
              ) / (item?.ratings?.length || 1)

            if (item?.owner?.blockedBy?.some((item) => item.id === user._id)) {
              return null
            }

            return (
              <>
                <Fragment key={index}>
                  <div
                    style={{ marginTop: index === 0 && '40px' }}
                    className={classes.comment}
                  >
                    {isHomePage &&
                      (item.isPinned ? (
                        <button
                          className={`${classes.linkToCommentContainer} ${classes.pinnedComment}`}
                        >
                          <span className={classes.betkoMessageTitle}>
                            PORUKA OD BETKA
                          </span>
                          <span className={classes.betkoMessageText}>
                            {item?.adminMessage}
                          </span>
                        </button>
                      ) : (
                        <button
                          className={classes.linkToCommentContainer}
                          onClick={() => navigate(`/${item?.linkToPost}`)}
                        >
                          <a>
                            <span className={classes.linkToCommentTitle}>
                              KLIKNI I DODAJ KOMENTAR
                            </span>
                            <span className={classes.linkToCommentType}>
                              {item?.type?.toUpperCase().replace(/_/g, ' ')}{' '}
                              <span className={classes.linkToCommentPostTitle}>
                                - {item?.postTitle}
                              </span>
                            </span>
                          </a>
                        </button>
                      ))}

                    {item.adminComment && (
                      <div className={classes.adminCommentMessage}>
                        <span className={classes.adminCommentTitle}>
                          Poruka od betka :
                        </span>
                        <span className={classes.adminCommentText}>
                          {item.adminComment}
                        </span>
                      </div>
                    )}

                    <div
                      className={`${classes.top} ${
                        item.parent !== null && classes.reverse
                      }`}
                    >
                      <div className={classes.commentImageInfo}>
                        <div>
                          <div className={classes.commentName}>
                            <span>{item?.owner?.userName}</span>
                          </div>

                          <div className={classes.commentImage}>
                            {item?.owner?.team &&
                            item?.owner?.team?.teamImage ? (
                              <img
                                src={`${SERVER_URL}/${item?.owner?.team?.teamImage?.url}`}
                                alt='navijac 1'
                              />
                            ) : (
                              <img
                                src='../teamPlaceholder.png'
                                alt='navijac 1'
                              />
                            )}
                          </div>
                        </div>

                        <div className={classes.dateAndId}>
                          <div className={classes.dateAndTime}>
                            <span>{dateWithHours(commentDate)}</span>
                          </div>
                          <div className={classes.commentId}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <span>Id komentara</span>
                              <span>{item._id}</span>
                            </div>
                            {item?.owner?.isMisterTipster && (
                              <div
                                className={classes.visitMisterTipsterProfileBtn}
                              >
                                <button
                                  onClick={() =>
                                    navigate(
                                      `mister_tipster/profil?user=${item.owner._id}`
                                    )
                                  }
                                  type='button'
                                >
                                  MISTER TIPSTER NALOG
                                </button>
                              </div>
                            )}
                            {item?.owner?._id !== user._id && (
                              <div
                                className={classes.reportCommentButtonContainer}
                              >
                                <button
                                  disabled={item?.reports?.includes(user?._id)}
                                  onClick={() => handleReportCommentModal(item)}
                                  type='button'
                                >
                                  Prijavi komentar
                                </button>
                                {user._id !== item?.owner?._id &&
                                  item?.owner?.status !== 'Admin' && (
                                    <button
                                      className={classes.blockUserButton}
                                      disabled={item?.owner?.blockedBy?.some(
                                        (item) => item.id === user._id
                                      )}
                                      onClick={() =>
                                        handleBlockUserModal(item?.owner)
                                      }
                                      type='button'
                                    >
                                      Blokiraj korisnika
                                    </button>
                                  )}
                              </div>
                            )}
                          </div>
                          <button
                            className={`${classes.giftButton} ${
                              !commentGift?.length > 0
                                ? classes.giftButtonRed
                                : ''
                            }`}
                            type='button'
                            onClick={(e) => {
                              setGiftCommentValue(commentGift)
                              setIsGiftModalVisible(true)
                            }}
                          >
                            <AiOutlineGift />
                          </button>
                        </div>
                      </div>

                      <div className={classes.commentInfo}>
                        <div style={{ width: '100%' }}>
                          <div className={classes.commentInfoHeader}>
                            <span style={{ color: 'red', fontSize: '18px' }}>
                              {item?.title || customTitleComment}
                            </span>
                          </div>

                          <div className={classes.commentDescription}>
                            {editComment &&
                            editCommentValue._id === item._id ? (
                              <textarea
                                onPaste={handlePaste}
                                name='editComment'
                                value={editCommentValue?.comment || ''}
                                rows={5}
                                onChange={(e) =>
                                  setEditCommentValue((prev) => {
                                    return {
                                      ...prev,
                                      comment: e.target.value,
                                    }
                                  })
                                }
                              />
                            ) : showComments.includes(item._id) ? (
                              <div
                                className={classes.commentContent}
                                dangerouslySetInnerHTML={renderTextWithLinks(
                                  innerHtml
                                )}
                              />
                            ) : (
                              <div
                                className={classes.commentContent}
                                dangerouslySetInnerHTML={renderTextWithLinks(
                                  cuttedInnerHtml
                                )}
                              />
                            )}
                          </div>
                        </div>

                        <div className={classes.commentShowMore}>
                          {item?.comment.length >= 170 && (
                            <button
                              type='button'
                              onClick={() => handleShowComments(item)}
                            >
                              {showComments.includes(item._id) ? (
                                <span>. . .Sakrij. . .</span>
                              ) : (
                                <span> . . .Prikaži više. . .</span>
                              )}
                            </button>
                          )}
                        </div>

                        <div
                          className={`${classes.bottom} ${
                            item.parent !== null && classes.reverse
                          }`}
                        >
                          <div className={classes.commentActions}>
                            <div>
                              <div className={classes.commentLikes}>
                                <button
                                  disabled={alreadyLiked}
                                  type='button'
                                  onClick={(e) =>
                                    handleLikeDislikeComment({
                                      type: 'like',
                                      action: { payload: item },
                                    })
                                  }
                                >
                                  <AiFillLike className={classes.likeIcon} />
                                </button>
                                <span>
                                  {+commentLikes + +commentAdminLikes}
                                </span>
                                <span>
                                  {+commentDislikes + +commentAdminDislikes}
                                </span>
                                <button
                                  disabled={alreadyDisliked}
                                  type='button'
                                  onClick={() =>
                                    handleLikeDislikeComment({
                                      type: 'dislike',
                                      action: { payload: item },
                                    })
                                  }
                                >
                                  <AiFillDislike
                                    className={classes.dislikeIcon}
                                  />
                                </button>
                              </div>

                              {manageComment && (
                                <>
                                  <div className={classes.commentEdit}>
                                    {editComment &&
                                    editCommentValue._id === item._id ? (
                                      <button
                                        type='button'
                                        onClick={() => handleSaveComment(item)}
                                      >
                                        Sačuvaj
                                      </button>
                                    ) : (
                                      <button
                                        type='button'
                                        onClick={() => handleEditComment(item)}
                                      >
                                        Izmeni
                                      </button>
                                    )}
                                  </div>

                                  <div className={classes.commentDelete}>
                                    <button
                                      type='button'
                                      onClick={() => {
                                        setSelectedComment(item)
                                        setIsDelModalVisible(true)
                                      }}
                                    >
                                      Obriši
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>

                            <div className={classes.ratingsContainer}>
                              <div
                                style={{ border: '2px solid black' }}
                                className={classes.ratingBox}
                              >
                                <span>{item?.ratings?.length || 0}</span>
                              </div>

                              <div
                                style={{ border: '2px solid black' }}
                                className={classes.ratingBox}
                              >
                                <span
                                  style={{
                                    textAlign: 'right',
                                    paddingRight: '2px',
                                  }}
                                >
                                  BR.
                                </span>
                                <span
                                  style={{
                                    textAlign: 'left',
                                    paddingLeft: '0px',
                                  }}
                                >
                                  OC.
                                </span>
                              </div>

                              <div className={classes.ratingBox}>
                                <button
                                  disabled={alreadyRatedComment(item)}
                                  className={`${activeRateColor(
                                    item,
                                    1,
                                    'crimsonBtn'
                                  )}`}
                                  onClick={() => handleRateComment(1, item)}
                                  style={{ color: 'crimson' }}
                                  type='button'
                                >
                                  1
                                </button>
                              </div>

                              <div className={classes.ratingBox}>
                                <button
                                  disabled={alreadyRatedComment(item)}
                                  className={`${activeRateColor(
                                    item,
                                    2,
                                    'redBtn'
                                  )}`}
                                  onClick={() => handleRateComment(2, item)}
                                  style={{ color: 'red' }}
                                  type='button'
                                >
                                  2
                                </button>
                              </div>

                              <div className={classes.ratingBox}>
                                <button
                                  disabled={alreadyRatedComment(item)}
                                  className={`${activeRateColor(
                                    item,
                                    3,
                                    'orangeBtn'
                                  )}`}
                                  onClick={() => handleRateComment(3, item)}
                                  style={{ color: 'orange' }}
                                  type='button'
                                >
                                  3
                                </button>
                              </div>

                              <div className={classes.ratingBox}>
                                <button
                                  disabled={alreadyRatedComment(item)}
                                  className={`${activeRateColor(
                                    item,
                                    4,
                                    'lightgreenBtn'
                                  )}`}
                                  onClick={() => handleRateComment(4, item)}
                                  style={{ color: 'lightgreen' }}
                                  type='button'
                                >
                                  4
                                </button>
                              </div>

                              <div className={classes.ratingBox}>
                                <button
                                  disabled={alreadyRatedComment(item)}
                                  className={`${activeRateColor(
                                    item,
                                    5,
                                    'greenBtn'
                                  )}`}
                                  onClick={() => handleRateComment(5, item)}
                                  style={{ color: 'green' }}
                                  type='button'
                                >
                                  5
                                </button>
                              </div>

                              <div
                                style={{ border: '2px solid black' }}
                                className={classes.ratingBox}
                              >
                                <span style={{ paddingRight: '2px' }}>PR</span>
                                <span
                                  style={{
                                    paddingLeft: '0',
                                    fontWeight: '500',
                                    fontSize: '9px',
                                    textAlign: 'left',
                                  }}
                                >
                                  OCENA
                                </span>
                              </div>

                              <div
                                style={{ border: '2px solid black' }}
                                className={`${classes.ratingBox} ${changeColors(
                                  averageRating
                                )}`}
                              >
                                <span>{averageRating?.toFixed(2)}</span>
                              </div>
                            </div>

                            <div>
                              <div className={classes.commentAnswerIt}>
                                <button
                                  type='button'
                                  onClick={(e) =>
                                    handleAnswerOnComment({ e: e, item: item })
                                  }
                                >
                                  Odgovori šta misliš o tome ?
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {selectedItem._id === item._id && (
                    <div
                      className={`${classes.answerOnCommentClass} ${
                        activeAnswer.includes(item._id) && classes.active
                      }`}
                    >
                      <div
                        onClick={() => handleShowPopUpLogin({ type: 'answer' })}
                        style={{ position: 'relative' }}
                      >
                        <textarea
                          style={{ pointerEvents: restricted && 'none' }}
                          disabled={restricted}
                          value={answerOnCommentValue}
                          onChange={(e) => handleChangeAnswerComment(e)}
                          className={classes.textArea}
                          rows={5}
                        />
                        <span className={classes.placeholder}>
                          upiši svoj odgovor
                        </span>
                      </div>
                      <div className={classes.leaveCommentButtonContainer}>
                        <button
                          onClick={(e) =>
                            handleAddComment({ e: e, type: 'answer' })
                          }
                          type='button'
                        >
                          Postavi odgovor
                        </button>
                      </div>
                    </div>
                  )}
                  {item?.repliesCount === 0 && (
                    <button
                      className={`${classes.showAnswersContainer} ${classes.beFirstToComment}`}
                      type='button'
                      onClick={(e) =>
                        handleAnswerOnComment({ e: e, item: item })
                      }
                    >
                      <span>UPIŠI ODGOVOR NA OVAJ KOMENTAR</span>
                      <div>
                        <RxTriangleUp />
                      </div>
                    </button>
                  )}
                  {item?.repliesCount > 0 && (
                    <button
                      onClick={() =>
                        item._id !== answersParentComment?.parent
                          ? setAnswersParentComment({
                              post: item.post,
                              parent: item._id,
                            })
                          : setAnswersParentComment(null)
                      }
                      className={`${classes.showAnswersContainer} ${
                        item._id === answersParentComment?.parent
                          ? classes.hideAnswers
                          : classes.showCommnets
                      }`}
                    >
                      <div>
                        {item._id !== answersParentComment?.parent ? (
                          <RxTriangleDown />
                        ) : null}
                      </div>
                      <span>
                        {item._id !== answersParentComment?.parent
                          ? `PRIKAŽI ODGOVORE ( br. odgovora - ${item?.repliesCount})`
                          : `SAKRIJ ODGOVORE`}
                      </span>
                    </button>
                  )}
                  {item._id === answersParentComment?.parent &&
                    answersPage > 1 && (
                      <div
                        onClick={() => setAnswersPage((page) => page - 1)}
                        className={`${classes.showAnswersContainer} ${classes.showPrevAnswers}`}
                      >
                        <div>
                          <RxTriangleUp />
                        </div>
                        <span>PRIKAŽI PRETHODNE ODGOVORE</span>
                      </div>
                    )}
                  {item._id === answersParentComment?.parent &&
                    answers &&
                    answers?.length > 0 &&
                    answers?.map((c, idx) => {
                      const manageChildrenComment =
                        c.owner && c.owner._id === user._id
                      const answerLikes = c.likes.length
                      const answerDislikes = c.dislikes.length
                      const answerAdminLikes = c.adminLikes
                      const answerAdminDislikes = c.adminDislikes
                      const answerAlreadyLiked = c.likes.includes(user._id)
                        ? true
                        : false
                      const answerAlreadyDisliked = c.dislikes.includes(
                        user._id
                      )
                        ? true
                        : false
                      const customTitleAnswer =
                        c.type === 'vasi_predlozi'
                          ? `na predlog - id ${item._id}`
                          : c.type === 'bet_kurs_novo'
                          ? `na premium - id ${item._id}`
                          : `na komentar - id ${item._id}`
                      const innerHtmlAnswer = c?.comment
                      const cuttedInnerHtmlAnswer = c?.comment.substring(0, 450)
                      const answerDate = c.editDate ? c.editDate : c.createdAt
                      const answerCommentGift = c?.gift
                      const averageRating =
                        c?.ratings?.reduce(
                          (acc, ratingObj) => acc + ratingObj?.rating,
                          0
                        ) / (c?.ratings?.length || 1)

                      if (c?.owner?.blockedBy?.some((k) => k.id === user._id)) {
                        return null
                      }
                      return (
                        <Fragment key={idx}>
                          <div
                            style={{ marginTop: index === 0 && '40px' }}
                            className={classes.comment}
                          >
                            <div
                              className={`${classes.top} ${
                                c.parent !== null && classes.reverse
                              }`}
                            >
                              <div className={classes.commentImageInfo}>
                                <div>
                                  <div className={classes.commentName}>
                                    <span>{c?.owner?.userName}</span>
                                  </div>

                                  <div className={classes.commentImage}>
                                    {c?.owner?.team &&
                                    c?.owner?.team?.teamImage ? (
                                      <img
                                        src={`${SERVER_URL}/${c?.owner?.team?.teamImage?.url}`}
                                        alt='navijac 1'
                                      />
                                    ) : (
                                      <img
                                        src='../teamPlaceholder.png'
                                        alt='navijac 1'
                                      />
                                    )}
                                  </div>
                                </div>

                                <div className={classes.dateAndId}>
                                  <div className={classes.dateAndTime}>
                                    <span>{dateWithHours(answerDate)}</span>
                                  </div>

                                  <div className={classes.commentId}>
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <span>Id komentara</span>
                                      <span>{c._id}</span>
                                    </div>
                                    {c?.owner?.isMisterTipster && (
                                      <div
                                        className={
                                          classes.visitMisterTipsterProfileBtn
                                        }
                                      >
                                        <button
                                          onClick={() =>
                                            navigate(
                                              `/mister_tipster/profil?user=${c?.owner?._id}`
                                            )
                                          }
                                          type='button'
                                        >
                                          MISTER TIPSTER NALOG
                                        </button>
                                      </div>
                                    )}
                                    <>
                                      {c?.owner?._id !== user._id && (
                                        <div
                                          className={
                                            classes.reportCommentButtonContainer
                                          }
                                        >
                                          <button
                                            disabled={c?.reports?.includes(
                                              user?._id
                                            )}
                                            onClick={() =>
                                              handleReportCommentModal(c)
                                            }
                                            type='button'
                                          >
                                            Prijavi komentar
                                          </button>
                                          {user._id !== c?.owner?._id &&
                                            c?.owner?.status !== 'Admin' && (
                                              <button
                                                className={
                                                  classes.blockUserButton
                                                }
                                                disabled={c?.owner?.blockedBy?.some(
                                                  (k) => k.id === user._id
                                                )}
                                                onClick={() =>
                                                  handleBlockUserModal(c?.owner)
                                                }
                                                type='button'
                                              >
                                                Blokiraj korisnika
                                              </button>
                                            )}
                                        </div>
                                      )}
                                      <button
                                        className={`${classes.giftButton} ${
                                          !answerCommentGift?.length > 0
                                            ? classes.giftButtonRed
                                            : ''
                                        }`}
                                        type='button'
                                        onClick={(e) => {
                                          setGiftCommentValue(answerCommentGift)
                                          setIsGiftModalVisible(true)
                                        }}
                                      >
                                        <AiOutlineGift />
                                      </button>
                                    </>
                                  </div>
                                </div>
                              </div>

                              <div className={classes.commentInfo}>
                                <div style={{ width: '100%' }}>
                                  {c.adminComment && (
                                    <div
                                      className={classes.adminCommentMessage}
                                    >
                                      <span
                                        className={classes.adminCommentTitle}
                                      >
                                        Poruka od betka :
                                      </span>
                                      <span
                                        className={classes.adminCommentText}
                                      >
                                        {c.adminComment}
                                      </span>
                                    </div>
                                  )}

                                  <div className={classes.commentInfoHeader}>
                                    <span
                                      style={{ color: 'rgb(27, 99, 222)' }}
                                    >{`Odgovor za: ${c?.answerTo?.userName}`}</span>
                                    <span style={{ color: 'rgb(27, 99, 222)' }}>
                                      {customTitleAnswer}
                                    </span>
                                  </div>

                                  <div className={classes.commentDescription}>
                                    {editComment &&
                                    editCommentValue._id === c._id ? (
                                      <textarea
                                        name='editComment'
                                        value={editCommentValue?.comment || ''}
                                        rows={5}
                                        onChange={(e) =>
                                          setEditCommentValue((prev) => {
                                            return {
                                              ...prev,
                                              comment: e.target.value,
                                            }
                                          })
                                        }
                                      />
                                    ) : showComments.includes(c._id) ? (
                                      <div
                                        className={classes.commentContent}
                                        dangerouslySetInnerHTML={renderTextWithLinks(
                                          innerHtmlAnswer
                                        )}
                                      />
                                    ) : (
                                      <div
                                        className={classes.commentContent}
                                        dangerouslySetInnerHTML={renderTextWithLinks(
                                          cuttedInnerHtmlAnswer
                                        )}
                                      />
                                    )}
                                  </div>
                                </div>

                                <div className={classes.commentShowMore}>
                                  {c?.comment?.length >= 170 && (
                                    <button
                                      type='button'
                                      onClick={() => handleShowComments(c)}
                                    >
                                      {showComments.includes(c._id) ? (
                                        <span>. . .Sakrij. . .</span>
                                      ) : (
                                        <span> . . .Prikaži više. . .</span>
                                      )}
                                    </button>
                                  )}
                                </div>

                                <div
                                  className={`${classes.bottom} ${
                                    c.parent !== null && classes.reverse
                                  }`}
                                >
                                  <div className={classes.commentActions}>
                                    <div>
                                      <div className={classes.commentLikes}>
                                        <button
                                          disabled={answerAlreadyLiked}
                                          type='button'
                                          onClick={(e) =>
                                            handleLikeDislikeComment({
                                              type: 'like',
                                              action: { payload: c },
                                            })
                                          }
                                        >
                                          <AiFillLike
                                            className={classes.likeIcon}
                                          />
                                        </button>
                                        <span>
                                          {+answerLikes + +answerAdminLikes}
                                        </span>
                                        <span>
                                          {+answerDislikes +
                                            +answerAdminDislikes}
                                        </span>
                                        <button
                                          disabled={answerAlreadyDisliked}
                                          type='button'
                                          onClick={() =>
                                            handleLikeDislikeComment({
                                              type: 'dislike',
                                              action: { payload: c },
                                            })
                                          }
                                        >
                                          <AiFillDislike
                                            className={classes.dislikeIcon}
                                          />
                                        </button>
                                      </div>

                                      {manageChildrenComment && (
                                        <>
                                          <div className={classes.commentEdit}>
                                            {editComment &&
                                            editCommentValue?._id === c._id ? (
                                              <button
                                                type='button'
                                                onClick={() =>
                                                  handleSaveComment(c)
                                                }
                                              >
                                                Sačuvaj
                                              </button>
                                            ) : (
                                              <button
                                                type='button'
                                                onClick={() =>
                                                  handleEditComment(c)
                                                }
                                              >
                                                Izmeni
                                              </button>
                                            )}
                                          </div>

                                          <div
                                            className={classes.commentDelete}
                                          >
                                            <button
                                              type='button'
                                              onClick={() => {
                                                setSelectedComment(c)
                                                setIsDelModalVisible(true)
                                              }}
                                            >
                                              Obriši
                                            </button>
                                          </div>
                                        </>
                                      )}
                                    </div>

                                    <div className={classes.ratingsContainer}>
                                      <div
                                        style={{ border: '2px solid black' }}
                                        className={classes.ratingBox}
                                      >
                                        <span>{c?.ratings?.length || 0}</span>
                                      </div>

                                      <div
                                        style={{ border: '2px solid black' }}
                                        className={classes.ratingBox}
                                      >
                                        <span
                                          style={{
                                            textAlign: 'right',
                                            paddingRight: '2px',
                                          }}
                                        >
                                          BR.
                                        </span>
                                        <span
                                          style={{
                                            textAlign: 'left',
                                            paddingLeft: '0px',
                                          }}
                                        >
                                          OC.
                                        </span>
                                      </div>

                                      <div className={classes.ratingBox}>
                                        <button
                                          disabled={alreadyRatedComment(c)}
                                          className={`${activeRateColor(
                                            c,
                                            1,
                                            'crimsonBtn'
                                          )}`}
                                          onClick={() =>
                                            handleRateComment(1, c)
                                          }
                                          style={{ color: 'crimson' }}
                                          type='button'
                                        >
                                          1
                                        </button>
                                      </div>

                                      <div className={classes.ratingBox}>
                                        <button
                                          disabled={alreadyRatedComment(c)}
                                          className={`${activeRateColor(
                                            c,
                                            2,
                                            'redBtn'
                                          )}`}
                                          onClick={() =>
                                            handleRateComment(2, c)
                                          }
                                          style={{ color: 'red' }}
                                          type='button'
                                        >
                                          2
                                        </button>
                                      </div>

                                      <div className={classes.ratingBox}>
                                        <button
                                          disabled={alreadyRatedComment(c)}
                                          className={`${activeRateColor(
                                            c,
                                            3,
                                            'orangeBtn'
                                          )}`}
                                          onClick={() =>
                                            handleRateComment(3, c)
                                          }
                                          style={{ color: 'orange' }}
                                          type='button'
                                        >
                                          3
                                        </button>
                                      </div>

                                      <div className={classes.ratingBox}>
                                        <button
                                          disabled={alreadyRatedComment(c)}
                                          className={`${activeRateColor(
                                            c,
                                            4,
                                            'lightgreenBtn'
                                          )}`}
                                          onClick={() =>
                                            handleRateComment(4, c)
                                          }
                                          style={{ color: 'lightgreen' }}
                                          type='button'
                                        >
                                          4
                                        </button>
                                      </div>

                                      <div className={classes.ratingBox}>
                                        <button
                                          disabled={alreadyRatedComment(c)}
                                          className={`${activeRateColor(
                                            c,
                                            5,
                                            'greenBtn'
                                          )}`}
                                          onClick={() =>
                                            handleRateComment(5, c)
                                          }
                                          style={{ color: 'green' }}
                                          type='button'
                                        >
                                          5
                                        </button>
                                      </div>

                                      <div
                                        style={{ border: '2px solid black' }}
                                        className={classes.ratingBox}
                                      >
                                        <span style={{ paddingRight: '2px' }}>
                                          PR
                                        </span>
                                        <span
                                          style={{
                                            paddingLeft: '0',
                                            fontWeight: '500',
                                            fontSize: '9px',
                                            textAlign: 'left',
                                          }}
                                        >
                                          OCENA
                                        </span>
                                      </div>

                                      <div
                                        style={{ border: '2px solid black' }}
                                        className={`${
                                          classes.ratingBox
                                        } ${changeColors(averageRating)}`}
                                      >
                                        <span>{averageRating?.toFixed(2)}</span>
                                      </div>
                                    </div>

                                    <div className={classes.commentAnswerIt}>
                                      <button
                                        type='button'
                                        onClick={(e) =>
                                          handleAnswerOnComment({
                                            e: e,
                                            item: c,
                                          })
                                        }
                                      >
                                        Odgovori šta misliš o tome ?
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {selectedItem._id === c._id && (
                            <div
                              className={`${classes.answerOnCommentClass} ${
                                activeAnswer.includes(c._id) && classes.active
                              }`}
                            >
                              <div
                                onClick={() =>
                                  handleShowPopUpLogin({ type: 'answer' })
                                }
                                style={{ position: 'relative' }}
                              >
                                <textarea
                                  style={{
                                    pointerEvents: restricted && 'none',
                                  }}
                                  disabled={restricted}
                                  value={answerOnCommentValue}
                                  onChange={(e) => handleChangeAnswerComment(e)}
                                  className={classes.textArea}
                                  rows={5}
                                />
                                <span className={classes.placeholder}>
                                  upiši svoj odgovor
                                </span>
                              </div>
                              <div
                                className={classes.leaveCommentButtonContainer}
                              >
                                <button
                                  onClick={(e) =>
                                    handleAddComment({ e: e, type: 'answer' })
                                  }
                                  type='button'
                                >
                                  Postavi odgovor
                                </button>
                              </div>
                            </div>
                          )}
                        </Fragment>
                      )
                    })}
                  {item._id === answersParentComment?.parent &&
                    (item?.repliesCount > answersPage * 10 ? (
                      <button
                        onClick={() => setAnswersPage((page) => page + 1)}
                        className={`${classes.showAnswersContainer} ${classes.showNextAnswers}`}
                      >
                        <div>
                          <RxTriangleDown />
                        </div>
                        <span>PRIKAŽI SLEDEĆE ODGOVORE</span>
                      </button>
                    ) : (
                      <button
                        className={`${classes.showAnswersContainer} ${classes.noMoreAnswers}`}
                      >
                        <div>
                          <RxTriangleDown />
                        </div>
                        <span>
                          NA OVAJ KOMENTAR NEMA VIŠE ODGOVORA - PREĐI NA SLEDEĆI
                          KOMENTAR
                        </span>
                        <div>
                          <RxTriangleDown />
                        </div>
                      </button>
                    ))}
                </Fragment>
              </>
            )
          })
          .slice(0, sliceValue)
      ) : (
        <div className={classes.noCommentsContainer}>
          <FaRegComment className={classes.noCommentsContainerIcon} />
          <span>Još uvek nema komentara</span>
        </div>
      )}
      <Pagination {...paginationProps} />

      {sliceValue >= treeData.length ? null : (
        <div
          style={{
            margin: '30px',
            boxSizing: 'border-box',
            width: '100%',
            textAlign: 'center',
          }}
        >
          <button
            className={classes.showMoreComments}
            onClick={() => setSliceValue((prev) => prev + 20)}
          >
            . . .KLIKNI I PRIKAŽI VIŠE KOMENTARA. . .
          </button>
        </div>
      )}

      <div
        className={`${classes.loginContainerModal} ${
          loginModal && classes.active
        }`}
      >
        <div className={classes.loginRow}>
          <IoClose
            onClick={() => {
              setLoginModal(false)
            }}
            style={{ color: 'red', fontSize: '50px', cursor: 'pointer' }}
          />
        </div>

        <div className={`${classes.loginRow} ${classes.loginRowInfo}`}>
          <p>Za komentarisanje je potrebno da budete ulogovani</p>
        </div>

        <div className={`${classes.loginRow} ${classes.logovanje}`}>
          <p>Logovanje</p>
          <form onSubmit={() => {}} className={classes.loginForm}>
            <div className={classes.loginFormRow}>
              <input
                type='email'
                name='email'
                placeholder='Email'
                required=''
                onChange={(e) => setLoginEmail(e.target.value)}
              />
            </div>

            <div className={classes.loginFormRow}>
              <input
                type='password'
                name='pswd'
                placeholder='Lozinka'
                required=''
                onChange={(e) => setLoginPassword(e.target.value)}
              />
            </div>

            <div className={`${classes.loginFormRow} ${classes.forgotPass}`}>
              <button
                onClick={() => navigate('/zaboravio_sam_lozinku')}
                type='button'
              >
                Zaboravio sam lozinku
              </button>
            </div>

            <div className={`${classes.loginFormRow} ${classes.loginButton}`}>
              <button
                disabled={loginMutation.isLoading}
                onClick={handleLogin}
                type='submit'
              >
                Login
              </button>
            </div>

            <div className={`${classes.loginFormRow} ${classes.registrujSe}`}>
              <span>Nemaš nalog?</span>
              <button
                onClick={() => {
                  setLoginModal(false)
                  setRegisterModal(true)
                }}
                type='button'
              >
                Registruj se
              </button>
            </div>
          </form>
        </div>
      </div>

      <div
        className={`${classes.registerContainerModal} ${
          registerModal && classes.active
        }`}
      >
        <div className={classes.loginRow}>
          <IoClose
            onClick={() => {
              setRegisterModal(false)
            }}
            style={{ color: 'red', fontSize: '50px', cursor: 'pointer' }}
          />
        </div>

        <div className={`${classes.loginRow} ${classes.loginRowInfo}`}>
          <p>Za komentarisanje je potrebno da budete ulogovani</p>
        </div>

        <div className={`${classes.loginRow} ${classes.logovanje}`}>
          <p>Registracija</p>
          {isError && <div className={classes.errorRegDiv}>{errorMessage}</div>}
          <form onSubmit={() => {}} className={classes.loginForm}>
            <div className={classes.loginFormRow}>
              <input
                type='text'
                // name='username'
                placeholder='Username'
                required=''
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </div>

            <div className={classes.loginFormRow}>
              <input
                type='email'
                name='email'
                placeholder='Email'
                required=''
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <div className={classes.loginFormRow}>
              <input
                className={classes.input}
                type='password'
                name='txt'
                placeholder='Lozinka'
                required=''
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <div className={classes.loginFormRow}>
              <input
                type='password'
                name='pswd'
                placeholder='Ponovi Lozinku'
                required=''
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '2px',
              }}
            >
              <label className={classes.labelChk} htmlFor='potvrda'>
                Potvrđujem da imam više od 18 godina
              </label>

              <input
                className={classes.checkbox}
                type='checkbox'
                name='potvrda'
                id='potvrda'
                checked={checked}
                onChange={() => setChecked((prev) => !prev)}
              />
            </div>

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: '2px',
              }}
            >
              <label className={classes.labelChk} htmlFor='opstiUslovi'>
                Saglasan sam i prihvatam opšte uslove{' '}
                <Link
                  style={{ color: 'rgb(180,0,255,1)' }}
                  to='/opšti_uslovi'
                  target='_blank'
                >
                  ( opšti uslovi )
                </Link>{' '}
                korišćenja betkotip.com
              </label>

              <div style={{ display: 'block' }}>
                <input
                  className={classes.checkbox}
                  type='checkbox'
                  name='opstiUslovi'
                  id='opstiUslovi'
                  checked={checkedTerms}
                  onChange={() => setCheckedTerms((prev) => !prev)}
                />
              </div>
            </div>

            <div className={`${classes.loginFormRow} ${classes.loginButton}`}>
              <button
                disabled={registerMutation.isLoading}
                onClick={handleRegistration}
                type='submit'
              >
                Registracija
              </button>
            </div>

            <div className={`${classes.loginFormRow} ${classes.registrujSe}`}>
              <button
                onClick={() => {
                  setRegisterModal(false)
                  setLoginModal(true)
                }}
                type='button'
              >
                Uloguj se
              </button>
            </div>
          </form>
        </div>
      </div>

      <div
        onClick={() => {
          setLoginModal(false)
          setRegisterModal(false)
        }}
        className={`${classes.loginOverlay} ${
          (loginModal || registerModal) && classes.active
        }`}
      />

      <Modal
        footer={[]}
        open={isReportOpen}
        title='Da li ste sigurni da želite da prijavite komentar?'
        onCancel={() => {
          setIsReportOpen(false)
          setReportComment({})
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '20px',
          }}
        >
          <Button
            onClick={(e) => {
              handleReportComment()
            }}
          >
            Prijavi
          </Button>
          <Button
            onClick={(e) => {
              setIsReportOpen(false)
              setReportComment({})
            }}
          >
            Odustani
          </Button>
        </div>
      </Modal>

      <Modal
        footer={[]}
        open={isDelModalVisible}
        title='Da li ste sigurni da želite da obrišete komentar?'
        onCancel={() => {
          setIsDelModalVisible(false)
          setSelectedItem({})
          setSelectedComment({})
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '20px',
          }}
        >
          <Button
            onClick={(e) => {
              handleDeleteComment(selectedComment)
              setIsDelModalVisible(false)
            }}
          >
            Obriši
          </Button>
          <Button
            onClick={(e) => {
              setIsDelModalVisible(false)
              setSelectedItem({})
              setSelectedComment({})
            }}
          >
            Odustani
          </Button>
        </div>
      </Modal>

      <Modal
        footer={[]}
        open={openBlockUserModal}
        title='Unesite razlog blokiranja korisnika.'
        onCancel={() => {
          setOpenBlockUserModal(false)
          setBlockedUser({})
        }}
      >
        <div className={classes.reasonContainer}>
          <textarea
            rows={6}
            className={classes.reasonInput}
            value={blockReason}
            onChange={(e) => setBlockReason(e.target.value)}
          />
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-evenly',
            marginTop: '20px',
          }}
        >
          <Button
            disabled={loadingBtn}
            onClick={(e) => {
              handleBlockUser(e)
            }}
          >
            {loadingBtn ? 'Blokiranje...' : 'Blokiraj'}
          </Button>
          <Button
            onClick={(e) => {
              setOpenBlockUserModal(false)
              setBlockedUser({})
            }}
          >
            Odustani
          </Button>
        </div>
      </Modal>

      {/* GIFT */}
      <Modal
        footer={[]}
        open={isGiftModalVisible}
        title=''
        closable={false}
        onCancel={() => {
          setIsGiftModalVisible(false)
          setGiftCommentValue('')
        }}
      >
        <div className={classes.giftModal}>
          <button
            onClick={() => {
              setGiftCommentValue('')
              setIsGiftModalVisible(false)
            }}
          >
            <img src='/close-mini-icon.svg' width={35} height={35} />
            <span>IZLAZ IZ PROZORA</span>
          </button>
          <p>
            {giftCommentValue?.length > 0
              ? giftCommentValue
              : 'TAKMIČI SE I OSVOJI'}
          </p>
        </div>
      </Modal>
    </div>
  )
}

export default Chat
