import React, { useEffect, useState } from 'react'
import classes from './MisterTipsterProfil.module.css'
import axios from 'axios'
import { message } from 'antd'
import MisterTipsterSingleMatch from '../components/MisterTipsterSingleMatch'
import { useNavigate } from 'react-router-dom'
import { IoStarSharp } from 'react-icons/io5'
import { IoMdArrowDropleft } from 'react-icons/io'
import Pagination from '../components/Pagination'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const MisterTipsterProfil = () => {
  const [todayMatch, setTodayMatch] = useState(null)
  const [selectedBets, setSelectedBets] = useState([])
  const [tipsterProfile, setTipsterProfile] = useState(null)
  const navigate = useNavigate()
  const [currentPage, setCurrentPage] = useState(1)
  const userId = new URLSearchParams(window.location.search).get('user')

  useEffect(() => {
    const getMatchForDate = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/getBetsByUser/${userId}`
        )
        setTodayMatch(response?.data)
      } catch (err) {
        message.error(
          err?.response?.data?.message || 'Greška! Molimo pokušajte kasnije!'
        )
      }
    }

    getMatchForDate()
  }, [userId])

  useEffect(() => {
    const getMatchForDate = async () => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/mister-tipster-profile/${userId}/${currentPage}`
        )
        setTipsterProfile(response?.data)
      } catch (err) {
        message.error(
          err?.response?.data?.message || 'Greška! Molimo pokušajte kasnije!'
        )
      }
    }

    getMatchForDate()
  }, [userId, currentPage])

  const paginationProps = {
    setCurrentPage: setCurrentPage,
    limit: 2,
    currentPage: currentPage,
    totalCount: tipsterProfile?.totalCount,
    totalPages: Math.ceil(tipsterProfile?.totalCount / 2),
    buttonLimit: 3,
  }

  return (
    <div className={classes.tipsterProfilePage}>
      <div className={classes.sticky}>
        <button className={classes.backButton} onClick={() => navigate(-1)}>
          <IoMdArrowDropleft />
          <span>NAZAD</span>
        </button>
        <div className={classes.mid}>
          <div className={classes.scoreWrapper}>
            <button
              className={classes.scoreTitle}
              onClick={() =>
                navigate('/mister_tipster/tabele/tabela-ranga?page=1')
              }
            >
              <span>KLIK - TABELA RANGA</span>
            </button>
            <div className={classes.scoreInner}>
              <div className={classes.scoreOrder}>
                <span>{tipsterProfile?.user?.positionTenDay}.</span>
              </div>
              <div className={classes.totalScore}>
                <span>{tipsterProfile?.user?.tablePoints?.rankPoints}</span>
              </div>
            </div>
          </div>
          <div>
            <h2>{tipsterProfile?.user?.rank}</h2>
            <div className={classes.imageWrapper}>
              <img
                src={
                  `${SERVER_URL}/${tipsterProfile?.user?.team?.teamImage?.url}` ||
                  '/no_team_image.png'
                }
                alt='player team image'
              />
            </div>
            <span>{tipsterProfile?.user?.team?.name}</span>
          </div>
          <div className={classes.scoreWrapper}>
            <button
              className={classes.scoreTitle}
              onClick={() =>
                navigate('/mister_tipster/tabele/mesecna-tabela?page=1')
              }
            >
              <span>KLIK - UK. MESEČNA TABELA</span>
            </button>
            <div className={classes.scoreInner}>
              <div className={classes.scoreOrder}>
                <span>{tipsterProfile?.user?.positionMonth}.</span>
              </div>
              <div className={classes.totalScore}>
                <span>{tipsterProfile?.user?.tablePoints?.monthPoints}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.tipsterUsername}>
          <span>{tipsterProfile?.user.userName}</span>
        </div>
      </div>
      <div className={classes.yearTableInfo}>
        <div className={classes.todayTipsTitle}>
          <span>AKTUELNI TIPOVI </span>
        </div>
        <button
          onClick={() =>
            navigate('/mister_tipster/tabele/godisnja-tabela?page=1')
          }
          className={classes.yearTableBtn}
        >
          KLIK GODIŠNJA TABELA
        </button>
        <div className={classes.positionYear}>
          <span>{tipsterProfile?.user?.positionYear}.</span>
        </div>
        <div className={classes.pointsYear}>
          <span>{tipsterProfile?.user?.tablePoints?.rankPoints}</span>
        </div>
      </div>
      <div className={classes.todayMatchTableHeader}>
        <div className={classes.x12}>
          <span>1</span>
          <span className={classes.xTip}>X</span>
          <span>2</span>
        </div>
        <div className={classes.tipsterMatchTitle}>
          <span>AKTUELNI TIPSTER MATCH</span>
        </div>
      </div>
      <div className={classes.tipsterMatchContainer}>
        {todayMatch &&
          todayMatch[0]?.pair?.map((pair, index) => (
            <MisterTipsterSingleMatch
              key={pair._id}
              isMatchPage={false}
              pair={pair}
              selectedBets={selectedBets}
              setSelectedBets={setSelectedBets}
              userBets={todayMatch}
              isTipsterProfile={true}
              index={index}
            />
          ))}
      </div>
      <div className={classes.globalTableHeader}>
        <button
          className={classes.globalTableBtn}
          onClick={() => {
            navigate('/mister_tipster/tabele/globalna-tabela?page=1')
          }}
        >
          <span className={classes.bettingSpan}>TIPOVANJE</span>{' '}
          <IoStarSharp className={classes.redStartIcon} /> KLIK - UK. RANG
        </button>
        <div className={classes.globalPosition}>
          <span>{tipsterProfile?.user?.position}. MESTO</span>
        </div>
      </div>

      <div className={classes.pairsContainer}>
        {tipsterProfile?.paginatedData &&
          tipsterProfile?.paginatedData[0]?.pair?.map((pair) => (
            <MisterTipsterSingleMatch
              key={pair._id}
              isMatchPage={false}
              pair={pair}
              selectedBets={selectedBets}
              setSelectedBets={setSelectedBets}
              userBets={tipsterProfile.paginatedData}
              isTipsterProfile={true}
              isTipsterProfileTable={true}
            />
          ))}
        <Pagination {...paginationProps} />
      </div>
    </div>
  )
}

export default MisterTipsterProfil
