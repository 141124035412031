import { useEffect, useState, useRef, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../config/axios'
import classes from './home.module.css'
import ReactPlayer from 'react-player'
import { SwiperSlide, Swiper } from 'swiper/react'
import left from '../components/assets/crvena leva strelica transparent.png'
import right from '../components/assets/zelena desna transparent.png'
import left2 from '../components/assets/NPSTRELICA (1).png'
import right2 from '../components/assets/NPDSTRELICA (1).png'
import AuthContext from '../context/AuthProvider'
import Comments from '../components/Comments'
import { IoStarSharp } from 'react-icons/io5'
import { Autoplay, Pagination } from 'swiper/modules'
import News from '../components/News'
import 'swiper/css/pagination'
const SERVER_URL = process.env.REACT_APP_SERVER_URL
const Home = () => {
  const navigate = useNavigate()
  const [data, setData] = useState()

  const [homeVideos, setHomeVideos] = useState([])
  const [homePopUp, setHomePopUp] = useState(false)
  const { setOpenSemafori, setLogin, auth } = useContext(AuthContext)

  const fetchData = async () => {
    try {
      await axios
        .get(`${SERVER_URL}/get-betko-info-wall`)
        .then((res) => {
          if (res.status === 200) {
            setData(res.data.data)
          }
        })
        .catch((err) => {
          console.error(err)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const swiperRef = useRef(null)

  const goToPrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev()
    }
  }

  const goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext()
    }
  }

  const fetchHomePageVideos = async () => {
    try {
      const response = await axios.get(`${SERVER_URL}/home-page-video-list`, {
        withCredentials: false,
      })
      if (
        response &&
        response.data &&
        response.data.items &&
        response.data.items.length > 0
      ) {
        setHomeVideos(response.data.items)
      }
    } catch (error) {
      console.log('error fetching home page videos!', error)
    }
  }

  const handleStorage = (e) => {
    const { checked } = e.target
    localStorage.setItem('homePopUp', checked)
  }

  useEffect(() => {
    fetchData()
    fetchHomePageVideos()
  }, [])

  const handleContextMenu = (event) => {
    event.preventDefault()
  }

  return (
    <div className={classes.container}>
      <News />
      <div className={classes.swiperContainer}>
        <section>
          <img
            className={classes.arrowsupleft}
            src={left2}
            onClick={goToPrevSlide}
          ></img>
          <img
            className={classes.arrowsleft}
            src={left}
            onClick={goToPrevSlide}
          ></img>
        </section>
        <div style={{ width: '61%', height: '100%', position: 'relative' }}>
          <Swiper
            slidesPerView={1}
            onSwiper={(swiper) => {
              swiperRef.current = swiper
            }}
            modules={[Autoplay, Pagination]}
            style={{
              '--swiper-pagination-color': '#00ff00',
              '--swiper-pagination-bullet-inactive-color': 'yellow',
              '--swiper-pagination-bullet-inactive-opacity': '1',
              '--swiper-pagination-bullet-size': '22px',
              '--swiper-pagination-bullet-horizontal-gap': '6px',
            }}
            pagination={{
              clickable: true,
            }}
            direction='horizontal'
            autoplay={{
              delay: 10000,
              disableOnInteraction: false,
            }}
          >
            {homeVideos.map((item, index) => {
              return (
                <SwiperSlide
                  key={index}
                  style={{ justifyContent: 'center', alignItems: 'center' }}
                >
                  {item && item.video && item.video.url && (
                    <ReactPlayer
                      url={`${SERVER_URL}/${item.video.url}`}
                      playing={true} // Enables autoplay
                      muted={true} // Mutes the video
                      loop
                      onContextMenu={handleContextMenu}
                      height={'320px'}
                      width={'100%'}
                      onClick={(e) => {
                        window.open(item.url, '_blank')
                      }}
                    />
                  )}
                </SwiperSlide>
              )
            })}
          </Swiper>

          {homePopUp && (
            <div className={classes.homePopUpContainer}>
              <div className={classes.buttonsContainer}>
                <button
                  onClick={() => {
                    if (auth && Object.keys(auth).length < 1) {
                      navigate('/registracija_i_logovanje')
                      setLogin(false)
                    } else {
                      navigate('/moj_nalog')
                    }
                  }}
                >
                  {auth && Object.keys(auth).length < 1
                    ? 'REGISTRACIJA'
                    : 'MOJ NALOG'}
                </button>
                <div>
                  <button onClick={() => setHomePopUp(false)}>
                    <img src='/close-mini-icon.svg' width={35} height={35} />
                  </button>
                  <span>IZLAZ</span>
                </div>
                <button
                  onClick={() => {
                    if (auth && Object.keys(auth).length < 1) {
                      navigate('/registracija_i_logovanje')
                      setLogin(true)
                    } else {
                      navigate('/moj_nalog')
                    }
                  }}
                >
                  {auth && Object.keys(auth).length < 1
                    ? 'LOGOVANJE'
                    : 'MOJ NALOG'}
                </button>
              </div>
              <div style={{ margin: '5px 0px' }}>
                <button
                  onClick={() => {
                    setHomePopUp(false)
                    setOpenSemafori(true)
                  }}
                >
                  <span>POGLEDAJTE</span>
                  <span>
                    SEM{' '}
                    <img
                      style={{ marginBottom: '7px' }}
                      src='/green_star.svg'
                      width={25}
                      height={25}
                    />{' '}
                    FOR !
                  </span>
                </button>
              </div>
              <div
                className={classes.homePopUpDesc}
                style={{ textAlign: 'center' }}
              >
                <p>
                  U SVAKOM TRENUTKU KLIKOM NA ZELENU{' '}
                  <img
                    style={{ display: 'inline-block' }}
                    src='/green_star.svg'
                    width={15}
                    height={15}
                  />{' '}
                  ZVEZDU U GORNJEM DESNOM UGLU, MOŽEŠ DA OTVORIŠ I DA POGLEDAŠ
                  REZULTATE NAŠIH PREDLOGA ZA IGRU, KOJI SU TI DOSTUPNI I PRE
                  POČETKA UTAKMICA U OKVIRU "PREMIUM" KORISNIČKIH PAKETA!
                </p>
                <p>TVOJ BETKO !</p>
              </div>

              <div className={classes.checkboxPopUpContainer}>
                <span>
                  AKO STE SHVATILI FUNKCIJU SEMAFORA{' '}
                  <img
                    style={{ display: 'inline-block' }}
                    src='/green_star.svg'
                    width={15}
                    height={15}
                  />{' '}
                  , OBELEŽITE DA VAM VIŠE NE ISKAČE OVAJ PROZOR
                </span>
                <div>
                  <input onChange={(e) => handleStorage(e)} type='checkbox' />
                </div>
              </div>
            </div>
          )}
        </div>
        <section>
          <img
            onClick={goToNextSlide}
            className={classes.arrowsupright}
            src={right}
          ></img>
          <img
            onClick={goToNextSlide}
            className={classes.arrowsright}
            src={right2}
          ></img>
        </section>
      </div>

      <div className={classes.newMenuContainer}>
        <div>
          <button
            onClick={() => navigate('/info_blok/653f1caa52a098b10de97b73')}
          >
            <span>
              <IoStarSharp style={{ fontSize: '40px' }} />
            </span>
            <span>KLIKNI I POGLEDAJ</span>
            <span>TOP SEMAFOR</span>
          </button>
        </div>

        <div>
          <button
            onClick={() => navigate('/info_blok/653f118a52a098b10de979ce')}
          >
            <span>
              <IoStarSharp style={{ fontSize: '40px' }} />
            </span>
            <span>KLIKNI I POGLEDAJ</span>
            <span>DANAS KOD BETKA</span>
          </button>
        </div>

        <div>
          <button
            onClick={() => navigate('/bet_kurs_novo/6549642d3ca6f705bb3fc418')}
          >
            <span>
              <IoStarSharp style={{ fontSize: '40px' }} />
            </span>
            <span>KLIKNI I POGLEDAJ</span>
            <span>PREMIUM PREDLOZI</span>
          </button>
        </div>
      </div>
      {
        <Comments
          commentType={'bet_kurs_novo'}
          adminComment={false}
          user={auth}
          title={'Comments'}
          id={''}
          isHomePage={true}
        />
      }
    </div>
  )
}

export default Home
