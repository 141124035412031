import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import BetkoShopLayout from './BetkoShopLayout'
import { betkoShopUsloviData } from './BetkoShopUsloviData'
import { Button, Form, Input, message, Modal } from 'antd'
import axios from '../../config/axios'
import LoginModal from '../../components/LoginModal'
import AuthContext from '../../context/AuthProvider'
import classes from './BetkoShopSaradnja.module.css'
import useRefreshToken from '../../hooks/useRefreshToken'
const SERVER_URL = process.env.REACT_APP_SERVER_URL

const BetkoShopSaradnja = () => {
  const { auth } = useContext(AuthContext)
  const [isSellerModalVisible, setIsSellerModalVisible] = useState(false)
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
  const refresh = useRefreshToken()
  const [form] = Form.useForm()

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768)
  const navigate = useNavigate()

  const handleRequestShop = async () => {
    try {
      const values = await form.validateFields()
      const response = await axios.post(
        `${SERVER_URL}/user/request-shop/${auth._id}`,
        { ...values }
      )
      message.success(response?.data || 'Zahtev je uspešno poslat!')
      setIsSellerModalVisible(false)
      refresh()
    } catch (error) {
      console.log(error)
      message.error(
        error?.response?.data?.message ||
          'Greška, pokušajte kasnije ili nas kontaktirajte!'
      )
    }
  }
  return (
    <BetkoShopLayout>
      <LoginModal
        setIsOpenLoginModal={setIsOpenLoginModal}
        isOpenLoginModal={isOpenLoginModal}
        title='Da bi ste postali prodavac morate se ulogovati!'
      />
      <Modal
        title='Unesite podatke o kompaniji'
        open={isSellerModalVisible} // Set to true to display the modal for this example
        onOk={handleRequestShop}
        onCancel={() => {
          setIsSellerModalVisible(false)
          form.resetFields()
        }}
        footer={[
          <Button
            key='back'
            onClick={() => {
              setIsSellerModalVisible(false)
              form.resetFields()
            }}
          >
            Otkaži
          </Button>,
          <Button key='submit' type='primary' onClick={handleRequestShop}>
            Potvrdi
          </Button>,
        ]}
      >
        <Form form={form} layout='vertical' className={classes.cf}>
          <Form.Item
            name='companyName'
            label='Naziv kompanije'
            rules={[
              { required: true, message: 'Molimo unesite naziv kompanije!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='companyAddress'
            label='Adresa kompanije'
            rules={[
              { required: true, message: 'Molimo unesite adresu kompanije!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='companyPlace'
            label='Mesto kompanije'
            rules={[
              { required: true, message: 'Molimo unesite mesto kompanije!' },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='companyPIB'
            label='PIB'
            rules={[
              { required: true, message: 'Molimo unesite PIB!' },
              { len: 9, message: 'PIB mora imati tačno 9 cifara!' },
              {
                pattern: /^[0-9]+$/,
                message: 'PIB može sadržavati samo cifre!',
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name='companyMIB'
            label='Matični broj'
            rules={[
              { required: true, message: 'Molimo unesite Matični broj!' },
              { len: 8, message: 'Matični broj mora imati tačno 8 cifara!' },
              {
                pattern: /^[0-9]+$/,
                message: 'Matični broj može sadržavati samo cifre!',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      <div className={`${classes.pageContainer}`}>
        <div className={classes.sticky}>
          <div className={classes.menuTitle}>
            <h2>KONTAKT - SARADNJA</h2>
          </div>
          <div className={classes.becomeBtns}>
            <div className={`${classes.navBtns} ${classes.becomeSellerBtn}`}>
              {auth.accessAdminShop === 'requested' ? (
                <button>ČEKA SE ODOBRENJE</button>
              ) : auth.accessAdminShop === 'approved' ? (
                <button
                  onClick={() =>
                    !isMobile && navigate('/betko-shop-admin/home')
                  }
                  className={classes.goToDash}
                >
                  KLIKOM NA OVO POLJE (ISKLJUČIVO NA RAČUNARU, NE I NA MOBILNOM
                  TELEFONU) MOŽETE PRISTUPITI VAŠEM ADMINISTRATORSKOM PROFILU.
                </button>
              ) : (
                <button
                  onClick={() => {
                    auth?._id
                      ? setIsSellerModalVisible(true)
                      : setIsOpenLoginModal(true)
                  }}
                >
                  KLIKNI I POSTANI PRODAVAC
                </button>
              )}
            </div>
            {(auth?.accessAdminShop === 'noAccess' || !auth?._id) && (
              <>
                <div
                  className={`${classes.navBtns} ${classes.becomeSellerBtn}`}
                >
                  {auth.accessAdminShop === 'requested' ? (
                    <button>ČEKA SE ODOBRENJE</button>
                  ) : auth.accessAdminShop === 'approved' ? (
                    <button
                      onClick={() =>
                        !isMobile && navigate('/betko-shop-admin/home')
                      }
                    >
                      KLIKOM NA OVO POLJE (ISKLJUČIVO NA RAČUNARU, NE I NA
                      MOBILNOM TELEFONU) MOŽETE PRISTUPITI VAŠEM
                      ADMINISTRATORSKOM PROFILU.
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        auth?._id
                          ? setIsSellerModalVisible(true)
                          : setIsOpenLoginModal(true)
                      }}
                    >
                      KLIKNI I POSTANI LICITATOR
                    </button>
                  )}
                </div>
                <div
                  className={`${classes.navBtns} ${classes.becomeSellerBtn}`}
                >
                  {auth.accessAdminShop === 'requested' ? (
                    <button>ČEKA SE ODOBRENJE</button>
                  ) : auth.accessAdminShop === 'approved' ? (
                    <button
                      onClick={() =>
                        !isMobile && navigate('/betko-shop-admin/home')
                      }
                    >
                      KLIKOM NA OVO POLJE (ISKLJUČIVO NA RAČUNARU, NE I NA
                      MOBILNOM TELEFONU) MOŽETE PRISTUPITI VAŠEM
                      ADMINISTRATORSKOM PROFILU.
                    </button>
                  ) : (
                    <button
                      onClick={() => {
                        auth?._id
                          ? setIsSellerModalVisible(true)
                          : setIsOpenLoginModal(true)
                      }}
                    >
                      KLIKNI I POSTANI ORGANIZATOR PUTA
                    </button>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
        <h3>POŠTOVANI IZLAGAČI</h3>
        <p>
          <a href='http://betkotip.com' target='_blank'>
            BETKOTIP.COM
          </a>{' '}
          je veoma posećena web stranica i zajednica koja se se bavi sportskim
          temama. U duhu sporta,{' '}
          <a href='http://betkotip.com' target='_blank'>
            BETKOTIP.COM
          </a>{' '}
          vas poziva, da nas ukoliko imate bilo kakvu ponudu, bilo kakvih
          proizvoda, koji su na bilo koji način povezivi sa sportom,
          kontaktirate, kako bismo ostvarili saradnju vezanu za izlaganje vaših
          proizvoda ili vaše ponude.
        </p>
        <p>
          Želimo i da napomenemo, da je prostor vezan za meni "LICITACIJE"
          nemenjen isključivo registrovanim humanitarnim fondacijama i
          humanitarnim organizacijama, koje deluju u potpunosti legalno. One su
          jedine, koje isključivo u humanitarne svrhe mogu da licitiraju
          predmete ili bilo šta, što je od javnog značaja ili ne, u cilju da
          prikupe sredstva za pomoć onima kojima je ona najpotrebnija.
        </p>
        <p>
          <a href='http://betkotip.com' target='_blank'>
            BETKOTIP.COM
          </a>{' '}
          vam nudi mogućnost da potpuno besplatno izložite svoju ponudu na{' '}
          <a href='http://betkotip.com' target='_blank'>
            BETKOTIP.COM
          </a>{' '}
          , kao i da primate porudžbine ili ponude uz naše posredstvo, te da
          kroz proces realizacije istih pospešite svoju prodaju ili u slučaju da
          ste humanitarna organizacija, pomognete onima kojima je to
          najpotrebnije,bez ikakvih troškova izlaganja proizvoda ili ponude,
          dakle potpuno besplatno.
        </p>
        <ul>
          <li>
            - Prvo što je potrebno jeste da obavite osnovnu registraciju na{' '}
            <a href='http://betkotip.com' target='_blank'>
              BETKOTIP.COM
            </a>
            , što možete učiniti klikom na ovaj link:{' '}
            <a href='http://betkotip.com' target='_blank'>
              BETKOTIP.COM
            </a>{' '}
          </li>
          <li>
            - Nakon što ste to učinili podnesite zahtev za otvaranje profila za
            izlaganje vašeg proizvoda/ponude, na tako što ćete kliknuti na ovaj
            link:{' '}
            <a
              href='https://www.messenger.com/login.php?next=https%3A%2F%2Fwww.messenger.com%2Ft%2F100090673000468%2F'
              target='_blank'
            >
              link
            </a>{' '}
            i dostaviti nam u tekstu poruke upis ZAINTERESOVANI SMO ZA SARADNJU.
          </li>
          <li>
            - Nakon toga ćemo vam se javiti, nakon čega ćemo vam dostaviti
            pravila korišćenja i izlaganja na{' '}
            <a href='http://betkotip.com' target='_blank'>
              BETKOTIP.COM
            </a>{' '}
            shop meniju, te parametre vašeg profila.
          </li>
        </ul>
        <p>
          Pošto zajedno i uz našu potpunu podršku, prođemo kroz ove brze, kratke
          i jednostavne korake, moćićete besplatno, nesmetano i sami da izlažete
          svoje proizvode i svoju ponudu na{' '}
          <a href='http://betkotip.com' target='_blank'>
            BETKOTIP.COM
          </a>{' '}
          shopu.
        </p>
        <p>Ponuda važi samo za pravna lica.</p>
        <p>Vaš BETKO !</p>
      </div>
    </BetkoShopLayout>
  )
}

export default BetkoShopSaradnja
