import React, { useContext, useEffect, useState } from 'react'
import classes from './MisterTipsterDefaultTable.module.css'
import { FaSearch } from 'react-icons/fa'
import { DatePicker } from 'antd'
import { useLocation, useNavigate } from 'react-router-dom'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { IoMdArrowDropleft } from 'react-icons/io'
import Pagination from './Pagination'
import Spinner from '../components/Spinner'
import AuthContext from '../context/AuthProvider'

dayjs.extend(utc)

const MisterTipsterDefaultTable = ({
  data,
  period,
  searchTerm,
  setSearchTerm,
  isLoading,
}) => {
  const navigate = useNavigate()
  const { auth } = useContext(AuthContext)
  const searchParams = new URLSearchParams(window.location.search)
  const periodParams = searchParams.get('period')
  const pageParams = searchParams.get('page')
  const periodFormat = period === 'year' ? 'YYYY.' : 'MM. YYYY.'
  const [from, setFrom] = useState(null)
  const locationState = useLocation().state
  const SERVER_URL = process.env.REACT_APP_SERVER_URL

  useEffect(() => {
    locationState?.from && setFrom(locationState?.from)
  }, [locationState])

  const [selectedPeriod, setSelectedPeriod] = useState(
    periodParams || dayjs().format(periodFormat)
  )
  const [currentPage, setCurrentPage] = useState(
    pageParams ? parseInt(pageParams) : 1
  )

  const handlePeriodChange = (date, dateString) => {
    setSelectedPeriod(dateString)
  }

  // Update URL when currentPage or selectedPeriod changes
  useEffect(() => {
    const params = new URLSearchParams()
    if (currentPage) params.set('page', currentPage)
    if (selectedPeriod && period !== 'global')
      params.set('period', selectedPeriod)

    navigate(`?${params.toString()}`, { replace: true })
  }, [currentPage, selectedPeriod, navigate])

  const paginationProps = {
    setCurrentPage: setCurrentPage,
    limit: 10,
    currentPage: currentPage,
    totalCount: data?.totalCount,
    totalPages: data?.pagesCount,
    buttonLimit: 3,
  }

  const formattedPeriod = periodParams
    ? dayjs(periodParams, periodFormat)
    : dayjs()

  const pointsPerPeriod =
    period === 'year'
      ? 'yearPoints'
      : period === 'month'
      ? 'monthPoints'
      : 'totalPoints'

  return (
    <div className={classes.container}>
      <div className={classes.sticky}>
        <button
          className={classes.backButton}
          onClick={() => {
            navigate(from || '/mister_tipster/tabele')
          }}
        >
          <IoMdArrowDropleft />
          <span>NAZAD</span>
        </button>
        <div className={classes.componentHeader}>
          <button
            className={classes.searchButton}
            onClick={() => console.log('searchTerm')}
          >
            <FaSearch />
          </button>
          <input
            type='text'
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <button
            className={classes.otherTableBtn}
            onClick={() =>
              navigate(
                `/mister_tipster/tabele/${
                  period !== 'global' ? 'globalna' : 'godisnja'
                }-tabela?page=1`
              )
            }
          >
            KLIKNI I POGLEDAJ <br />
            {period !== 'global' ? 'GLOBALNU' : 'GODIŠNJU'} TABELU
          </button>
        </div>
        <div
          className={`${classes.tableTitle} ${
            period === 'global' ? classes.isGlobalTable : ''
          }`}
        >
          <button
            className={classes.gameDescription}
            onClick={() => navigate(`/mister_tipster/opis-igre`)}
          >
            OPIS IGRE
          </button>
          <h3>
            {period === 'year'
              ? 'GODIŠNJA'
              : period === 'month'
              ? 'MESEČNA'
              : 'GLOBALNA'}{' '}
            TABELA
          </h3>{' '}
          <div className={classes.pickerWrapper}>
            <DatePicker
              picker={period === 'year' ? 'year' : 'month'}
              placeholder={period === 'year' ? 'GODINA' : 'MESEC'}
              onChange={handlePeriodChange}
              defaultValue={formattedPeriod}
              className={classes.tablePeriodPicker}
              format={period === 'year' ? 'YYYY.' : 'MM. YYYY.'}
            />
          </div>
        </div>
        <div className={classes.tableHeader}>
          <div className={classes.tableHeaderRank}>PLASMAN</div>
          <div className={classes.tableHeaderTeam}>DRES</div>
          <div className={classes.tableHeaderPlayer}>IGRAČ</div>
          <div className={classes.tableHeaderTotalScore}>ZBIR.KV.</div>
        </div>
        {data?.currentUser?._id && currentPage === 1 && (
          <div
            className={`${classes.tableItem} ${classes.currentUserTableItem}`}
          >
            <div className={classes.tableItemRank}>
              {data?.currentPosition || data?.currentUser?.position}.
            </div>
            <div className={classes.tableItemTeam}>
              <img
                src={
                  data?.currentUser?.team?.teamImage?.url
                    ? `${SERVER_URL}/${data?.currentUser?.team?.teamImage?.url}`
                    : '/no_team_image.png'
                }
              />
            </div>
            <div
              className={classes.tableItemPlayer}
              onClick={() => navigate('/mister_tipster')}
            >
              {data?.currentUser?.username || data?.currentUser?.userName}
            </div>
            <div className={classes.tableItemTotalScore}>
              {data?.currentUser?.tablePoints[pointsPerPeriod] || 0}
            </div>
          </div>
        )}
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={classes.tableBody}>
          {data?.paginatedData?.map((user, index) => (
            <div className={classes.tableItem} key={user._id}>
              <div className={classes.tableItemRank}>
                {(currentPage - 1) * 10 + index + 1}.
              </div>
              <div className={classes.tableItemTeam}>
                <img
                  src={
                    user?.team?.teamImage?.url
                      ? `${SERVER_URL}/${user?.team?.teamImage?.url}`
                      : '/no_team_image.png'
                  }
                  alt='team'
                />
              </div>
              <div
                className={classes.tableItemPlayer}
                onClick={() => {
                  navigate(
                    `/mister_tipster${
                      auth?._id !== user?._id ? `/profil?user=${user._id}` : ''
                    }`
                  )
                }}
              >
                {user?.username || user?.userName}
              </div>
              <div className={classes.tableItemTotalScore}>
                {user?.tablePoints[pointsPerPeriod] || 0}
              </div>
            </div>
          ))}
          {data?.paginatedData?.length > 0 ? (
            <div className={classes.pagination}>
              <Pagination {...paginationProps} />
            </div>
          ) : (
            <p className={classes.noData}>Nema podataka za izabrani period!</p>
          )}
        </div>
      )}
    </div>
  )
}

export default MisterTipsterDefaultTable
