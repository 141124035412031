import React from 'react'
import classes from './MisterTipsterOpisIgre.module.css'
import { IoStarSharp } from 'react-icons/io5'
import { IoMdArrowDropleft } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

const MisterTipsterOpisIgre = () => {
  const navigate = useNavigate()

  return (
    <div className={classes.tipsterDescriptionPage}>
      <div className={classes.sticky}>
        <button className={classes.backButton} onClick={() => navigate(-1)}>
          <IoMdArrowDropleft />
          <span>NAZAD</span>
        </button>
        <div className={classes.tipsterDescriptionTitle}>
          <IoStarSharp className={classes.redStartIcon} />
          OPIS IGRE
          <IoStarSharp className={classes.redStartIcon} />
        </div>
      </div>
      <h2>OTVORI I OSVOJI !</h2>
      <p className={classes.red}>
        DOBRODOŠAO U "MISTER TIPSTER" TAKMIČENJE ! <br /> POSTANI ŠAMPION,
        TAKMIČI SE I OSVOJI NAGRADE !
      </p>
      <p>
        OVO TAKMIČENJE SE ODVIJA U DESETODNEVNIM, TRIDESETODNEVNIM I GODIŠNJIM
        CIKLUSIMA. POBEDNICI SVAKOG OD CIKLUSA OSVAJAJU TAKMIČARSKE NAGRADE !
      </p>
      <p className={classes.blue}>
        PRAVILA IGRE MOŽEŠ POGLEDATI U OKVIRU MENIJA "PRAVILA IGRE" U SVOM
        "MISTER TIPSTER" NALOGU. TRAJANJE TAKMIČENJA PO CIKLUSIMA, KAO I
        TAKMIČARSKE NAGRADE ZA POBEDNIKE ISTIH, PRIKAZANI SU TI U "FONDU
        TAKMIČARSKIH NAGRADA".
      </p>
      <p>
        ŠTA JE TO ŠTO TI TREBA DA URADIŠ DA BI POSTAO DEO OVOG TAKMIČENJA I DA
        BI UČESTVOVAO U NJEMU ? VRLO JE JEDNOSTAVNO. SVE ŠTO TREBA DA URADIŠ DA
        BI AUTOMATSKI POSTAO DEO TAKMIČENJA JESTE DA SE REGISTRUJEŠ NA
        BETKOTIP.COM
      </p>
      <p className={classes.blue}>
        OK. REGISTROVAO SI SE, ŠTA SAD ? ULOGUJ SE NA PODATKE KOJE SI POSTAVIO
        PRI REGISTRACIJI, DA BI UČESTVOVAO U IGRI, I MOŽEŠ DA POČNEŠ !
      </p>
      <p>
        ŠTA DALJE RADIŠ ? UĐI U SVOJ ''TIPSTER MATCH'', ODABERI SVOJE TIPOVE ZA
        POSTAVLJENE UTAKMICE, I TO JE TO.
      </p>
      <p className={classes.blue}>
        SVAKI DAN ĆEŠ DOBITI OD NAS E-MAIL KAKO BISMO TE PODSETILI DA ODIGRAŠ
        SVOJ DNEVNI ''MISTER TIPSTER MATCH''. ODIGRAJ GA I TI SI SVOJ DEO POSLA
        OBAVIO.
      </p>
      <p>SUPER JEDNOSTAVNO !</p>
      <p className={classes.blue}>
        KAKO DA PRATIŠ REZUZLTATE SVOJE IGRE I SVOJ PLASMAN NA TABELI ?
      </p>
      <p>
        DAKLE, KADA SI ODIGRAO SVOJ ''MISTER TIPSTER MATCH'' NAKON ZAVRŠETKA
        TIPOVANIH MATCH-EVA'' ZBIR POGOĐENIH KVOTA SE PRENOSI U TABELE
        TAKMIČENJA. U ZAVISNOSTI OD TOGA O KOM JE PERIODU TAKMIČENJA REČ, ZBIR
        POGOĐENIH KVOTA SVIH TAKMIČARA SE PRENOSOM U TABELE SELEKTUJE, NA OSNOVU
        ČEGA SE ODREĐUJE PLASMAN TAKMIČARA. JEDNOSTAVNIM ULAZOM U TABELU PO
        IZBORU, U SVAKOM TRENUTKU MOŽEŠ VIDETI SVOJ TRENUTNI PLASMAN, KAO I
        PLASMAN PO ZAVRŠETKU CIKLUSA.
      </p>
      <p className={classes.blue}>KOLIKO JE TAKMIČENJE ZANIMLJIVO ?</p>
      <p className={classes.blue}>
        TAKMIČENJE JE VEOMA ZANIMLJIVO IZ VIŠE RAZLOGA. NAJPRE, OBRTI I
        PREOKRETI JESU VRLO MOGUĆI, JER JE U OPTICAJU VEĆI ZBIR KVOTA, I MOŽE SE
        NE RETKO DESITI DA JEDAN TAKMIČAR POGODI VEĆI DEO ISTIH ILI SVE, A
        DRUGI, KOJI JE PRE TOG DANA BIO BOLJE RANGIRAN, PROMAŠI VEĆI DEO ISTIH
        ILI SVE. TO SU SITUACIJE KOJE MOGU DA TE U JEDNOM DANU DOVEDU OD RECIMO,
        PRIMERA RADI, SA NEKOG PETOG, ŠESTOG MESTA NA TABELI DO PRVOG MESTA ILI
        DRUGOG, TREĆEG MESTA I OSVAJANJA TAKMIČARSKIH NAGRADA !
      </p>
      <p className={classes.blue}>
        ZATO NEMOJ DA PROPUŠTAŠ DANE U IGRI, AKO ŽELIŠ DA TI BUDEŠ TAJ KOJI ĆE
        OSVOJITI TAKMIČARSKU NAGRADU, TAKMIČI SE SVAKI DAN I POSTANI POBEDNIK !
      </p>
      <p>
        TAKOĐE, RANG TAKMIČENJE SE ANULIRA BODOVONO NA SVAKIH DESET DANA, A
        MESEČNO NA TRIDESET, TE GODIŠNJE NA GODINU DANA I POČINJE SE NOVI
        CIKLUS, GDE SVI TAKMIČARI U ISTI ULAZE SA NULA BODOVA. NA OVAJ NAČIN, I
        AKO SI IMAO MOŽDA LOŠIJI PERIOD GAĐANJA ISHODA U PROŠLOM CIKLUSU, TO U
        SLEDEĆEM CIKLUSU NEMA NIKAKVO ZNAČENJE, JER SE U NOVI CIKLUS UKLJUČUJEŠ
        RAVONPRAVNO SA SVIM DRUGIM TAKMIČARIMA
      </p>
      <p className={classes.blue}>
        TAKOĐE, KLIKOM NA KORISNIČKO IME BILO KOG TAKMIČARA, MOŽEŠ POGLEDATI
        NJEGOV UČINAK U TIPOVANJU, KAO I NJEGOVE POSTAVLJENE TIPOVE ZA AKTUELNI
        TIPSTERSKI DAN, KAO ŠTO SVAKI TAKMIČAR MOŽE POGLEDATI I TVOJE REZULTATE
        GAĐANJA. SVE OVO MOŽEŠ POGLEDATI I NA ''MISTER TIPSTER PROFIL-U'' NA
        SVAKOM OD KOMENTARA REGISTROVANIH TAKMIČARA ILI DIREKTNIM KLIKOM NA
        TAKMIČARA U SAMIM TABELAMA TAKMIČENJA U OKVIRU BETKOTIP.COM PLATFORME,
        NARAVNO, PRE TOGA JE POTREBNO DA SE REGISTRUJEŠ I DA BUDEŠ ULOGOVAN NA
        BETKOTIP.COM
      </p>
      <p className={classes.register}>REGISTRUJ SE, ULOGUJ SE, PA DA POČNEMO !</p>
      <span>POGLEDAJ TIPOVE SVIH TAKMIČARA ! TAKMIČI SE I OSVOJI !</span>
      <p className={classes.green}>BUDI POBEDNIK !</p>
    </div>
  )
}

export default MisterTipsterOpisIgre
