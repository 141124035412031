import React, { useContext, useEffect, useState } from 'react'
import BetkoShopLayout from './BetkoShopLayout'
import classes from './BetkoCart.module.css'
import { Form, Input, Tooltip, message } from 'antd'
import CartContext from '../../context/CartContext'
import { FaInfo, FaMinus, FaPlus } from 'react-icons/fa'
import { RxTriangleLeft } from 'react-icons/rx'
import AuthContext from '../../context/AuthProvider'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import { useNavigate, useLocation } from 'react-router-dom'
import formatPrice from '../../utils/FormatPriceCurrency'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const BetkoCart = () => {
  const {
    cart,
    cartWithData,
    addToCart,
    removeFromCart,
    totalAmount,
    resetCart,
  } = useContext(CartContext)
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()
  const { auth } = useContext(AuthContext)
  const [form] = Form.useForm()
  const location = useLocation()
  const [tourQuantity, setTourQuantity] = useState(
    location?.state?.tourQuantity
  )
  const auction = location?.state?.auction
  const sportTour = location?.state?.sportTour
  const [bidPrice, setBidPrice] = useState(0)

  useEffect(() => {
    if (!auth?._id || cartWithData?.length === 0) {
      navigate('/betko_shop')
    }
  }, [])

  const handleCreateOrder = async () => {
    const reqValues = await form.validateFields()

    if (bidPrice > 999999999999) {
      message.error('Maksimalan iznost licitacije je 999.999.999.999 RSD!')
      return
    }

    if (auction) {
      try {
        await axiosPrivate.put(`${SERVER_URL}/product-new-bid`, {
          productId: auction._id,
          userId: auth._id,
          price: bidPrice,
          customer: {
            ...reqValues,
          },
        })
        message.success('Čestitamo, uspešno ste licitirali!')
        navigate(-1)
      } catch (error) {
        message.error(
          error?.response?.data?.message ||
            'Došlo je do greške! Molimo pokušajte kasnije ili kontaktirajte našu korisničku podršku! '
        )
      }
      return
    }

    let orderedProducts = cartWithData?.map((product) => {
      return {
        productId: product.id,
        quantity: product.quantity,
        atrDesc: product.atrDesc,
      }
    })

    if (sportTour) {
      orderedProducts = [
        {
          productId: sportTour._id,
          quantity: tourQuantity,
          atrDesc: location?.state?.tourDesc || '',
        },
      ]
    }

    const reqBody = {
      customer_id: auth?._id,
      orderedProducts,
      customer: {
        ...reqValues,
      },
    }

    try {
      await axiosPrivate.post(`${SERVER_URL}/create-order`, reqBody)
      message.success(
        'Četitamo, uspešno ste obavili kupovinu! Sve potrebne informacije biće Vam dostavljene na Vašu email adresu!'
      )
      navigate('/betko_shop')
      resetCart()
    } catch (error) {
      message.error(
        error?.response?.data?.message ||
          'Došlo je do greške! Molimo pokušajte kasnije ili kontaktirajte našu korisničku podršku! '
      )
    }
  }

  useEffect(() => {
    form.setFieldsValue({
      name: auth?.name,
      address: auth?.address,
      place: auth?.place,
      zipCode: auth?.zipCode,
      phoneNumber: auth?.phoneNumber,
    })
  }, [])

  return (
    <BetkoShopLayout>
      <div className={classes.cartContainer}>
        <div className={classes.cartHeader}>
          <button
            className={classes.backFromCartBtn}
            onClick={() => navigate(-1)}
          >
            <RxTriangleLeft /> NAZAD
          </button>
          <span className={classes.cartHeaderInfo}>
            UNESI PODATKE POTREBNE ZA{' '}
            {auction
              ? 'LICITACIJU'
              : sportTour
              ? 'REZERVACIJU'
              : 'PORUDŽBINU / DOSTAVU'}
          </span>
        </div>
        <Form
          form={form}
          className={classes.userInfoForm}
          onFinish={handleCreateOrder}
          initialValues={{ name: auth?.name, isPopular: false }}
        >
          <div className={classes.formItem}>
            <label>IME I PREZIME</label>
            <Form.Item
              name='name'
              rules={[
                {
                  required: true,
                  min: 2,
                  message: 'Molimo unesite ispravano ime i prezime!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className={classes.formItem}>
            <label>ADRESA</label>
            <Form.Item
              name='address'
              rules={[
                {
                  required: true,
                  min: 2,
                  message: 'Molimo unesite ispravanu adresu!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className={classes.formItem}>
            <label>MESTO</label>
            <Form.Item
              name='place'
              rules={[
                {
                  required: true,
                  min: 2,
                  message: 'Molimo unesite ispravano mesto stanovanja!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className={classes.formItem}>
            <label>POŠTANSKI BROJ</label>
            <Form.Item
              name='zipCode'
              rules={[
                {
                  required: true,
                  message: 'Molimo unesite poštanski broj!',
                },
                {
                  len: 5,
                  message: 'Poštanski broj mora imati tačno 5 cifara!',
                },
                {
                  pattern: /^\d{5}$/,
                  message: 'Molimo unesite ispravan poštanski broj (5 cifara)!',
                },
              ]}
            >
              <Input maxLength={5} type='number' />
            </Form.Item>
          </div>

          <div className={classes.formItem}>
            <label>BROJ TELEFONA</label>
            <Form.Item
              name='phoneNumber'
              rules={[
                {
                  required: true,
                  pattern: /^[0-9]{9,11}$/,
                  message:
                    'Molimo unesite ispravan broj telefona (9-11 cifara)!',
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>

          <div className={classes.cartTitle}>
            <span>
              TVOJA{' '}
              {auction
                ? `LICITACIJA - ${auction?.title}`
                : sportTour
                ? `SPORT TURA: ${sportTour?.title}`
                : 'KORPA'}
            </span>
          </div>
          {auction && (
            <div className={classes.auctionForm}>
              <span>IZNOS KOJI LICITIRAŠ</span>
              <input
                value={bidPrice}
                type='text'
                placeholder='UNESITE IZNOS'
                onChange={(e) => setBidPrice(e.target.value)}
              />
            </div>
          )}

          {cartWithData?.length > 0 && !auction && (
            <table className={classes.attributesTable}>
              <thead>
                <th>NAZIV</th>
                <th>CENA</th>
                <th>KOLIČINA</th>
                <th>ATRIBUTI</th>
              </thead>
              <tbody className={classes.cartProducts}>
                {(sportTour ? [sportTour] : cartWithData)?.map((product) => (
                  <tr key={product._id}>
                    <td className={classes.productName}>{product.title}</td>
                    <td>{product.price} RSD</td>
                    <td>
                      <div className={classes.tdQuantity}>
                        <button
                          className={classes.quantityBtn}
                          onClick={() => {
                            sportTour
                              ? setTourQuantity((curr) =>
                                  curr > 1 ? curr - 1 : 1
                                )
                              : removeFromCart(product?._id)
                          }}
                          type='button'
                        >
                          <FaMinus />
                        </button>

                        {sportTour ? tourQuantity : product?.quantity}

                        <button
                          className={classes.quantityBtn}
                          onClick={() => {
                            sportTour
                              ? setTourQuantity((curr) => curr + 1)
                              : addToCart(
                                  product?._id,
                                  +product.quantity + 1,
                                  product?.atrDesc
                                )
                          }}
                          type='button'
                        >
                          <FaPlus />
                        </button>
                      </div>
                    </td>
                    <td className={classes.tdAtribute}>
                      <Tooltip
                        title={
                          product.atrDesc || location?.state?.tourDesc || ''
                        }
                        placement='top'
                      >
                        <button className={classes.quantityBtn} type='button'>
                          <FaInfo />
                        </button>
                      </Tooltip>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <div className={classes.totalSum}>
            <span>UKUPNO:</span>
            <span>
              {auction
                ? formatPrice(bidPrice)
                : sportTour
                ? formatPrice(sportTour?.price * tourQuantity)
                : totalAmount}
            </span>
          </div>
          <button type='submit' className={classes.submitBtn}>
            KLIKNI I POTVRDI{' '}
            {auction ? `LICITACIJU` : sportTour ? 'REZERVACIJU' : 'PORUDŽBINU'}
          </button>
          <div className={classes.orderInfo}>
            <p>INFORMACIJE O DOSTAVI</p>
            <p>
              {auction
                ? 'ZA SVE INFORMACIJE VEZANE ZA VAŠU LICITACIJU , PO POTREBI ĆE VAS DIREKTNO KONTAKTIRATI IZLAGAČ LICITACIJE.'
                : sportTour
                ? 'ZA SVE INFORMACIJE VEZANE ZA VAŠU REZERVACIJU, DIREKTNO ĆE VAS KONTAKTIRATI IZLAGAČ PONUDE I U DOGOVORU SA VAMA UTANAČITI SVE POTREBNE DETALJE.'
                : 'ZA SVE INFORMACIJE VEZANE ZA DOSTAVU VAŠE PORUDŽBINE NA VAŠU ADRESU, DIREKTNO ĆE VAS KONTAKTIRATI IZLAGAČ PROIZVODA I U DOGOVORU SA VAMA PREMA VAMA ODGOVARAJUĆEM NAČINU DOSTAVE, UTANAČITI DETALJE POŠILJKE KA VAMA.'}
            </p>
          </div>
        </Form>
      </div>
    </BetkoShopLayout>
  )
}

export default BetkoCart
